import * as React from "react"

function TaglineMobile(props) {
  return (
    <svg
      style={{
        position: `absolute`,
      }}
      xmlns="http://www.w3.org/2000/svg"
      // width={196.16}
      // height={185.27}
      viewBox="0 0 196.16 185.27"
      {...props}
    >
      <defs>
        <clipPath id="b">
          <path
            d="m87.13 97.58 1.8 1.79a1.86 1.86 0 0 0-.31-.26l-1.8-1.79a1.48 1.48 0 0 1 .31.26Z"
            fill="none"
          />
        </clipPath>
        <clipPath id="c">
          <path
            d="m86.84 97.58 1.79 1.79a1.86 1.86 0 0 0-.31-.26l-1.79-1.79a2.25 2.25 0 0 1 .31.26Z"
            fill="none"
          />
        </clipPath>
        <clipPath id="d">
          <path
            d="M86.24 97.58 88 99.37a1.86 1.86 0 0 0-.31-.26l-1.8-1.79a1.48 1.48 0 0 1 .35.26Z"
            fill="none"
          />
        </clipPath>
        <linearGradient
          id="a"
          x1={52.06}
          y1={99.51}
          x2={52.06}
          y2={132.7}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#bac9d1" />
          <stop offset={0.34} stopColor="#8aa0a8" />
          <stop offset={1} stopColor="#637785" />
        </linearGradient>
      </defs>
      <path d="M51.24 6.63v34.94h-6.86V9.6a5.51 5.51 0 0 0-.86-3Z" />
      <path
        d="M45.46 40.51V9.61a5.72 5.72 0 0 0-.27-1.9h5v32.8Z"
        fill="#c51f30"
      />
      <path
        d="M146 37.52a3.12 3.12 0 0 0 .62 1l1.8 1.8-1.8-1.8a3.12 3.12 0 0 1-.62-1ZM145.19 33.69c0-.34-.07-.69-.09-1 .02.31.05.66.09 1ZM145.31 34.67ZM145.87 37.19c-.08-.23-.15-.48-.22-.75.07.27.14.56.22.75Z"
        fill="none"
      />
      <path
        d="M145.7 37.23a3.24 3.24 0 0 0 .63 1l1.79 1.77-1.79-1.79a3.24 3.24 0 0 1-.63-.98ZM144.89 33.39l-.09-1ZM145 34.38l-.12-1ZM145.58 36.89a7.15 7.15 0 0 1-.23-.74 7.15 7.15 0 0 0 .23.74Z"
        fill="none"
      />
      <path
        d="M145.11 36.63a3 3 0 0 0 .63 1l1.79 1.8-1.79-1.8a3 3 0 0 1-.63-1ZM144.3 32.8c0-.34-.07-.68-.09-1 .02.32.05.66.09 1Z"
        fill="none"
      />
      <path
        d="M144.42 33.78c0-.32-.09-.65-.12-1 .03.35.07.68.12 1ZM145 36.3c-.08-.23-.15-.48-.22-.75.05.27.12.52.22.75Z"
        fill="none"
      />
      <path d="M146.35 6.59c8.4 0 10 8.37 10 17.38 0 10-1.55 18.51-9.75 18.51s-9.71-7.79-9.71-18.22c-.03-8.56 1.39-17.67 9.46-17.67ZM146.6 38c3.12 0 2.53-9.94 2.53-13.84 0-4.79.15-13-2.54-13s-2.54 7.91-2.54 12.46c0 4-.3 14.43 2.55 14.43" />
      <path
        d="M146.58 41.41a6.86 6.86 0 0 1-7-4.54c-1.46-3.42-1.65-8.53-1.65-12.6 0-11.33 2.68-16.6 8.41-16.6a7.16 7.16 0 0 1 7.16 4.68c1.2 2.65 1.75 6.35 1.75 11.65 0 12.2-2.59 17.41-8.67 17.41Zm0-31.34a2.43 2.43 0 0 0-1.73.74c-1.76 1.77-1.9 6.53-1.89 12.1v1.75c0 5.91.1 11.83 2 13.75a2.29 2.29 0 0 0 1.63.71 2.5 2.5 0 0 0 1.79-.77c1.84-1.88 1.92-7 1.83-12.49v-2.37c0-5.88-.13-10.89-1.93-12.69a2.35 2.35 0 0 0-1.68-.73Z"
        fill="#c51f30"
      />
      <path
        d="M129.68 14.15a4.54 4.54 0 0 0-.53-.12 4.54 4.54 0 0 1 .53.12ZM129.29 13.46a4.54 4.54 0 0 1 1.22.91 4.54 4.54 0 0 0-1.22-.91Z"
        fill="none"
      />
      <path d="M117.1 6.75v6.79c-1.84-2.31-2.79-2.31-5.48-2.31-2 0-2.46.77-2.46 3 0 2.93 2.33 3.37 4.33 4.57 2.74 1.64 4.4 5.36 4.62 9.58.36 6.83-.48 13.18-5.88 13.18H103l-.16-7.27c2.1 2.09 3.08 2.61 5.22 2.61 2.71 0 3.21-3.29 3.21-7.54 0-3.3-1.62-5.37-3.87-6.3-3.36-1.4-5.37-4.13-5.37-8.79 0-5 2-7.53 5.9-7.53Z" />
      <path
        d="m104.11 40.51-.11-3.76a6.23 6.23 0 0 0 4.11 1.25c4.29 0 4.29-5.81 4.29-8.6a7.45 7.45 0 0 0-4.54-7.31c-3.17-1.31-4.71-3.86-4.71-7.8a8 8 0 0 1 1.27-5A4.21 4.21 0 0 1 108 7.83h8V11c-1.28-.85-2.52-.85-4.4-.85-3.11 0-3.54 2-3.54 4.09 0 3 2 4 3.7 4.87.38.2.79.4 1.15.62 2.33 1.4 3.9 4.73 4.11 8.71.3 5.66-.34 9.23-2 10.93a3.71 3.71 0 0 1-2.85 1.13Z"
        fill="#c51f30"
      />
      <path d="M97.48 6.58v8.05c-1.21-2.08-2.11-3.13-3.77-3.13h-1.39v27.21a5.66 5.66 0 0 0 .82 2.86h-7.72V11.5H84c-1.44 0-2.5 1.12-3.53 3.06v-8Z" />
      <path
        d="M86.51 40.51v-29a1.07 1.07 0 0 0-1.07-1.08H84a3.8 3.8 0 0 0-2.46.92V7.66h14.87v3.77a4 4 0 0 0-2.7-1h-1.38a1.08 1.08 0 0 0-1.08 1.08v27.21a6.07 6.07 0 0 0 .27 1.79Z"
        fill="#c51f30"
      />
      <path d="M177.26 6.59v6.46c-1.82-2-2.2-2.1-4.35-2.1h-4.18v5.85H176v4.78h-7.32v21.73a11 11 0 0 0 1.09 5 5.29 5.29 0 0 1-.9.07 6.73 6.73 0 0 1-2.79-.59 19.46 19.46 0 0 0-2.63-1.39 3 3 0 0 0-.92-.12 5.62 5.62 0 0 0-1.48.2 8.07 8.07 0 0 0 .74-3.52V9.88a5.3 5.3 0 0 0-.79-3.29Z" />
      <path
        d="M168.24 47.24a4.93 4.93 0 0 1-1.63-.44 9.64 9.64 0 0 1-1-.54 8.46 8.46 0 0 0-1.81-.9 3.7 3.7 0 0 0-1.17-.17 10.27 10.27 0 0 0 .25-2.25V9.86a6.73 6.73 0 0 0-.26-2.19h13.5v2.91a5.15 5.15 0 0 0-3.27-.7h-4.18a1.08 1.08 0 0 0-1.02 1.12v5.81a1.07 1.07 0 0 0 1.08 1.07H175v2.63h-6.24a1.08 1.08 0 0 0-1.08 1.07s0 18.77 0 21.75a13.34 13.34 0 0 0 .56 3.91Z"
        fill="#c51f30"
      />
      <path d="M75.13 6.69v34.88h-5.8l-6.8-22.14v16c0 2.34-.24 4.11.87 6.17h-5.8V9.88a5.36 5.36 0 0 0-.86-3.19h7.8l5.64 16v-16Z" />
      <path
        d="m70.13 40.51-6.58-21.39a1.08 1.08 0 0 0-1-.76h-.17a1.09 1.09 0 0 0-.91 1.07v17a13.22 13.22 0 0 0 .35 4.09h-3.19V9.89a6.46 6.46 0 0 0-.27-2.12h5.38l5.39 15.33a1.06 1.06 0 0 0 1 .72h.18a1.07 1.07 0 0 0 .9-1.06v-15h2.84v32.75Z"
        fill="#c51f30"
      />
      <path d="M26.62 0v16.35h4.5v-5c0-2.24.13-2.68-.85-4.7H38v35h-6.9V21.16h-4.5v20.41h-6.85V7.19a5.18 5.18 0 0 0-.86-3 38 38 0 0 0 3.89-1.84A34.65 34.65 0 0 0 26.62 0Z" />
      <path
        d="M32.19 40.51V21.17a1.07 1.07 0 0 0-1.07-1.07h-4.5a1.07 1.07 0 0 0-1.08 1.07v19.34h-4.7V7.2a5.46 5.46 0 0 0-.47-2.43c1-.41 1.95-.9 2.93-1.44.78-.42 1.53-.86 2.24-1.31v14.34a1.07 1.07 0 0 0 1.08 1.07h4.5a1.06 1.06 0 0 0 1.07-1.06v-5.5a8.18 8.18 0 0 0-.31-3.13h5v32.77Z"
        fill="#c51f30"
      />
      <path
        d="M66.28 82a3.13 3.13 0 0 0 .63 1l1.79 1.8-1.79-1.8a3.13 3.13 0 0 1-.63-1ZM65.47 78.19c0-.33-.07-.68-.09-1 .02.32.06.67.09 1ZM65.59 79.18q-.06-.48-.12-1 .06.52.12 1ZM66.15 81.7Z"
        fill="none"
      />
      <path
        d="M66 81.74a3.16 3.16 0 0 0 .62 1l1.8 1.79-1.8-1.79a3.16 3.16 0 0 1-.62-1ZM65.17 77.9l-.09-1ZM65.29 78.88l-.12-1ZM65.86 81.4Z"
        fill="none"
      />
      <path
        d="M65.39 81.14a3.13 3.13 0 0 0 .63 1L67.81 84 66 82.15a3.13 3.13 0 0 1-.61-1.01ZM64.58 77.3c0-.33-.06-.68-.09-1 .03.32.06.7.09 1ZM64.7 78.29q-.06-.48-.12-1 .06.52.12 1ZM65.26 80.81Z"
        fill="none"
      />
      <path d="M66.63 51.09c8.4 0 10 8.38 10 17.39 0 10-1.55 18.5-9.75 18.5s-9.71-7.78-9.71-18.21c-.02-8.56 1.39-17.68 9.46-17.68Zm.25 31.45c3.12 0 2.53-9.94 2.53-13.84 0-4.8.15-13.05-2.54-13.05s-2.54 7.91-2.54 12.46c0 4-.3 14.43 2.55 14.43" />
      <path
        d="M66.86 85.92a6.86 6.86 0 0 1-7-4.54c-1.46-3.42-1.64-8.53-1.64-12.6 0-11.33 2.67-16.61 8.4-16.61a7.16 7.16 0 0 1 7.16 4.68c1.19 2.66 1.74 6.36 1.74 11.64.01 12.22-2.58 17.43-8.66 17.43Zm0-31.34a2.36 2.36 0 0 0-1.72.74c-1.77 1.77-1.91 6.52-1.9 12.1v1.75c0 5.9.1 11.83 2 13.75a2.32 2.32 0 0 0 1.64.71 2.45 2.45 0 0 0 1.78-.78c1.85-1.87 1.92-7 1.84-12.48V68c0-5.88-.13-10.9-1.93-12.7a2.36 2.36 0 0 0-1.69-.72Z"
        fill="#c51f30"
      />
      <path d="M118.47 51.09v6.47c-1.82-2-2.2-2.1-4.35-2.1h-4.18v5.81h7.32v4.77h-7.31v21.78a11 11 0 0 0 1.09 5 6.73 6.73 0 0 1-.91.06 6.57 6.57 0 0 1-2.78-.59 20.59 20.59 0 0 0-2.63-1.38 3 3 0 0 0-.93-.12 6.28 6.28 0 0 0-1.48.19 8 8 0 0 0 .75-3.52V54.39a5.28 5.28 0 0 0-.85-3.27Z" />
      <path
        d="M109.45 91.75a5.13 5.13 0 0 1-1.62-.44 9.64 9.64 0 0 1-1-.54 8 8 0 0 0-1.81-.9 4 4 0 0 0-1.17-.18 10.18 10.18 0 0 0 .25-2.24V54.37a6.84 6.84 0 0 0-.26-2.2h13.5v2.91a5.23 5.23 0 0 0-3.28-.69h-4.18a1.07 1.07 0 0 0-1.07 1.08v5.81a1.07 1.07 0 0 0 1.07 1.07h6.25V65h-6.25a1.1 1.1 0 0 0-.76.31 1.08 1.08 0 0 0-.31.76s0 18.77 0 21.74a13.7 13.7 0 0 0 .64 3.94Z"
        fill="#c51f30"
      />
      <path d="M44.25 51.09a44.41 44.41 0 0 1 7.61.72v7.55c-1.19-2.14-2.39-3.6-5.08-3.6a3.81 3.81 0 0 0-3.85 2.85c-.65 1.87-.72 7.23-.72 12.4 0 4.74-.26 11.58 4.89 11.58a5.81 5.81 0 0 0 4.59-2.46v6.26a23.79 23.79 0 0 1-5.82.72c-2.75 0-5.81-.7-7.53-2.68-2.7-3.12-3.41-8.74-3.41-13.38 0-10.18 1.07-19.96 9.32-19.96Z" />
      <path
        d="M45.88 86.05c-3 0-5.44-.84-6.72-2.32-2-2.34-3.15-6.84-3.15-12.67 0-4.26.17-9.63 1.5-13.44s3.44-5.45 6.75-5.45a42.92 42.92 0 0 1 6.53.55v3.41a5.73 5.73 0 0 0-4-1.44 4.91 4.91 0 0 0-4.86 3.58c-.64 1.85-.77 6.25-.77 12.75v.66c0 3.67 0 8.24 2.29 10.56a5 5 0 0 0 3.67 1.43 6.15 6.15 0 0 0 3.52-1.07v3a22.62 22.62 0 0 1-4.76.45Z"
        fill="#c51f30"
      />
      <path d="M139.69 51.09v6.47c-1.82-2-2.2-2.1-4.36-2.1h-4.17v5.81h7.31v4.77h-7.31v15.49h5.25c1.8 0 2.1-.66 3.35-2v6.54h-15.47V54.36a5.34 5.34 0 0 0-.85-3.27Z" />
      <path
        d="M125.38 85V54.37a6.84 6.84 0 0 0-.26-2.2h13.5v2.91a5.23 5.23 0 0 0-3.28-.69h-4.18a1.07 1.07 0 0 0-1.07 1.08v5.81a1.07 1.07 0 0 0 1.07 1.07h6.24V65h-6.24a1.07 1.07 0 0 0-1.07 1.07v15.47a1.07 1.07 0 0 0 1.07 1.08h5.25a4.11 4.11 0 0 0 2.27-.54V85Z"
        fill="#c51f30"
      />
      <path d="M161.17 51.09v6.47c-1.82-2-2.2-2.1-4.35-2.1h-4.18v5.81H160v4.77h-7.32v15.49h5.24c1.81 0 2.1-.66 3.35-2v6.54h-15.49V54.36a5.35 5.35 0 0 0-.86-3.27Z" />
      <path
        d="M146.86 85V54.37a6.84 6.84 0 0 0-.26-2.2h13.5v2.91a5.22 5.22 0 0 0-3.27-.69h-4.18a1.08 1.08 0 0 0-1.08 1.08v5.81a1.07 1.07 0 0 0 1.08 1.07h6.24V65h-6.24a1.07 1.07 0 0 0-1.08 1.07v15.47a1.08 1.08 0 0 0 1.08 1.08h5.25a4.13 4.13 0 0 0 2.27-.54V85Z"
        fill="#c51f30"
      />
      <path d="M97.26 51.09v6.47c-1.82-2-2.2-2.1-4.36-2.1h-4.17v5.81H96v4.77h-7.27v20h-6.87V54.36a5.34 5.34 0 0 0-.86-3.27Z" />
      <path
        d="M83 85V54.37a6.84 6.84 0 0 0-.26-2.2h13.5v2.91a5.23 5.23 0 0 0-3.28-.69h-4.18a1.07 1.07 0 0 0-1.07 1.08v5.81a1.07 1.07 0 0 0 1.07 1.07H95V65h-6.27a1.07 1.07 0 0 0-1.07 1.07V85Z"
        fill="#c51f30"
      />
      <path d="M108.9 97.32v34.94H102v-32a5.51 5.51 0 0 0-.86-3Z" />
      <path
        d="M103.13 131.2v-30.9a5.44 5.44 0 0 0-.28-1.9h5v32.8Z"
        fill="#c51f30"
      />
      <path d="M123.66 97.32a45.43 45.43 0 0 1 7.61.71v7.56c-1.19-2.14-2.39-3.61-5.08-3.61a3.81 3.81 0 0 0-3.85 2.85c-.65 1.87-.72 7.23-.72 12.4 0 4.74-.26 11.58 4.89 11.58a5.83 5.83 0 0 0 4.59-2.45v6.25a23.82 23.82 0 0 1-5.83.73c-2.74 0-5.8-.71-7.52-2.69-2.7-3.12-3.41-8.74-3.41-13.38 0-10.17 1.05-19.95 9.32-19.95Z" />
      <path
        d="M125.29 132.27c-3 0-5.44-.84-6.72-2.31-2-2.35-3.15-6.85-3.15-12.68 0-4.26.17-9.63 1.5-13.44s3.44-5.44 6.75-5.44a42.92 42.92 0 0 1 6.53.55v3.4a5.73 5.73 0 0 0-4-1.44 4.91 4.91 0 0 0-4.86 3.58c-.64 1.86-.77 6.25-.77 12.75v.66c0 3.67 0 8.24 2.3 10.56a5 5 0 0 0 3.67 1.43 6.08 6.08 0 0 0 3.52-1.07v3a22.62 22.62 0 0 1-4.77.45Z"
        fill="#c51f30"
      />
      <path d="M155.3 97.3v35h-6.9v-20.45h-4.5v20.41h-6.86v-32a5.31 5.31 0 0 0-.85-3h7.71V107h4.5v-5c0-2.24.14-2.68-.85-4.7Z" />
      <path
        d="M149.49 131.2v-19.34a1.08 1.08 0 0 0-1.07-1.08h-4.5a1.09 1.09 0 0 0-1.08 1.08v19.34h-4.7v-30.92a5.74 5.74 0 0 0-.27-1.89h5v8.66a1.08 1.08 0 0 0 1.08 1.07h4.5a1.06 1.06 0 0 0 1.07-1.06v-5.5a8.18 8.18 0 0 0-.31-3.13h5v32.77Z"
        fill="#c51f30"
      />
      <path
        fill="none"
        d="m44.26 132.56 1.8 1.8-1.8-1.8zM43.96 132.27l1.8 1.79-1.8-1.79zM43.37 131.67l1.8 1.8-1.8-1.8z"
      />
      <path d="M57.24 98.43v6.35a4.38 4.38 0 0 0-3.83-1.78h-1.75c-1.9 0-3.12.89-3.12 3.61 0 2.35.82 3 2.56 3V113h-.66c-2.84 0-2.4 7.71-2.4 10.25 0 1.51-.23 6 1.64 6s1.42-8.23 1.42-9.52V116h9v4.33h-2v6.46c0 6-4.24 7-8.77 7-5.74 0-8.48-3.4-8.48-10.26 0-2.83.43-10.47 3.09-11.54-2.07-1.47-2.37-3.61-2.37-6.25 0-3.49.76-7.28 4.31-7.28Z" />
      <path
        d="M49.35 132.7c-2.55 0-4.37-.71-5.56-2.17s-1.85-3.85-1.85-7c0-6 1.23-10.07 2.41-10.54a1.08 1.08 0 0 0 .67-.87 1.07 1.07 0 0 0-.45-1c-1.6-1.14-1.91-2.77-1.91-5.37 0-6.21 2.43-6.21 3.23-6.21h10.28v3a5.38 5.38 0 0 0-2.76-.6h-1.75c-1.91 0-4.19.82-4.19 4.69 0 2.33.82 3.61 2.56 4V112a2.54 2.54 0 0 0-1.4.75c-1.64 1.67-1.72 5.66-1.66 9.61v1.39c0 2.39 0 4.82 1.19 6a2.12 2.12 0 0 0 1.52.64 1.67 1.67 0 0 0 1.22-.53c1-1 1.39-3.78 1.28-9.47v-3.27H59v2.19h-.93a1.07 1.07 0 0 0-1.07 1.01V126.78c.05 4.22-2.11 5.92-7.65 5.92Z"
        fill="#c51f30"
      />
      <path d="M52.1 125.51c0 .3-.05.59-.08.86v.89Z" fill="url(#a)" />
      <g
        clipPath="url(#b)"
        style={{
          isolation: "isolate",
        }}
      >
        <path
          d="m87.13 97.58 1.8 1.79a1.86 1.86 0 0 0-.31-.26l-1.8-1.79a1.48 1.48 0 0 1 .31.26"
          fill="#74919d"
        />
      </g>
      <g
        clipPath="url(#c)"
        style={{
          isolation: "isolate",
        }}
      >
        <path
          d="m86.84 97.58 1.79 1.79a1.86 1.86 0 0 0-.31-.26l-1.79-1.79a2.25 2.25 0 0 1 .31.26"
          fill="#5e7079"
        />
      </g>
      <g
        clipPath="url(#d)"
        style={{
          isolation: "isolate",
        }}
      >
        <path
          d="M86.24 97.58 88 99.37a1.86 1.86 0 0 0-.31-.26l-1.8-1.79a1.48 1.48 0 0 1 .31.26"
          fill="#495b64"
        />
      </g>
      <path d="M97.34 133.54a12.39 12.39 0 0 0 2.12 5.16 6.84 6.84 0 0 1-.91.06 7.25 7.25 0 0 1-2.86-.61c-1.18-.51-1.51-1.06-2.73-1.44a3.86 3.86 0 0 0-1.06-.12 6.88 6.88 0 0 0-1.69.2 8.86 8.86 0 0 0 .41-3.65 124.5 124.5 0 0 0-2-13.57c-.6-2.88-1.53-5.44-2.68-6.23v18.83h-6.87v-31.84a5.38 5.38 0 0 0-.85-3h10.57c5 0 7 2.33 7.19 7.64.08 2.68-.75 5.75-3.77 6.76 1.27 1.1 2.12 4.18 2.75 7.21 1.23 5.9 1.83 10.93 2.38 14.6ZM86.07 108.3c2.2 0 3-.84 2.84-3.16s-.7-3.46-2.35-3.46h-.62v6.62h.13" />
      <path
        d="M97.56 137.61a6.25 6.25 0 0 1-1.42-.43 7.15 7.15 0 0 1-1-.59 6.25 6.25 0 0 0-1.8-.89 4.58 4.58 0 0 0-1.38-.18h-.28a10.29 10.29 0 0 0 .08-2.48 124.55 124.55 0 0 0-2-13.69c-.52-2.52-1.49-5.77-3.12-6.9a1.1 1.1 0 0 0-.61-.19 1.11 1.11 0 0 0-.5.12 1.09 1.09 0 0 0-.57 1v17.76h-4.8v-30.81a6.23 6.23 0 0 0-.27-1.93h8.9c4.3 0 6 1.79 6.12 6.61.13 4-1.84 5.3-3 5.7a1.07 1.07 0 0 0-.37 1.83c.84.72 1.65 2.95 2.41 6.62 1 4.59 1.54 8.69 2 12 .13.92.25 1.79.37 2.58a13.39 13.39 0 0 0 1.24 3.87Zm-11.62-37a1.07 1.07 0 0 0-1.07 1.07v6.61a1.08 1.08 0 0 0 1 1.08h.16a4.1 4.1 0 0 0 3-1 4.31 4.31 0 0 0 .86-3.28c-.17-3-1.32-4.46-3.43-4.46Z"
        fill="#c51f30"
      />
      <path
        d="M100.9 173.87a3.12 3.12 0 0 0 .62 1l1.8 1.8-1.8-1.8a3.12 3.12 0 0 1-.62-1ZM100.09 170c0-.33-.07-.68-.09-1 0 .35.05.7.09 1ZM100.21 171q-.08-.48-.12-1 .05.54.12 1ZM100.77 173.54a7.37 7.37 0 0 1-.22-.75 7.37 7.37 0 0 0 .22.75Z"
        fill="none"
      />
      <path
        d="M100.6 173.57a3.13 3.13 0 0 0 .63 1l1.79 1.8-1.79-1.8a3.13 3.13 0 0 1-.63-1ZM99.79 169.74l-.09-1ZM99.91 170.72l-.12-1ZM100.47 173.24Z"
        fill="none"
      />
      <path
        d="M100 173a3.12 3.12 0 0 0 .62 1l1.8 1.8-1.8-1.8a3.12 3.12 0 0 1-.62-1ZM99.2 169.14c0-.33-.07-.68-.09-1 .02.32.05.67.09 1Z"
        fill="none"
      />
      <path
        d="M99.32 170.13q-.07-.48-.12-1 .05.52.12 1ZM99.88 172.65c-.08-.23-.15-.49-.22-.75.07.26.14.52.22.75Z"
        fill="none"
      />
      <path d="M101.25 142.93c8.4 0 10 8.38 10 17.39 0 10-1.55 18.5-9.76 18.5s-9.7-7.78-9.7-18.21c-.03-8.56 1.39-17.68 9.46-17.68Zm.25 31.45c3.12 0 2.53-9.94 2.53-13.84 0-4.8.15-13.06-2.54-13.06S99 155.4 99 160c0 4-.3 14.43 2.55 14.43" />
      <path
        d="M101.48 177.76a6.87 6.87 0 0 1-7-4.55c-1.46-3.41-1.65-8.52-1.65-12.59 0-11.33 2.67-16.61 8.41-16.61a7.17 7.17 0 0 1 7.16 4.68c1.19 2.66 1.74 6.35 1.74 11.64.01 12.22-2.58 17.43-8.66 17.43Zm0-31.34a2.42 2.42 0 0 0-1.73.73c-1.77 1.78-1.9 6.53-1.89 12.1V161c0 5.9.1 11.83 2 13.75a2.28 2.28 0 0 0 1.63.7 2.46 2.46 0 0 0 1.79-.77c1.84-1.87 1.92-7 1.83-12.48v-2.38c0-5.87-.14-10.89-1.93-12.69a2.38 2.38 0 0 0-1.68-.71Z"
        fill="#c51f30"
      />
      <path
        d="m58.8 164.86-.09-1ZM58.51 164.56c0-.33-.07-.68-.1-1 .03.32.06.67.1 1ZM57.91 164l-.09-1ZM58 165l-.12-1ZM54.93 173.87a3.13 3.13 0 0 0 .63 1l1.79 1.8-1.79-1.8a3.13 3.13 0 0 1-.63-1ZM54.24 171q-.08-.48-.12-1 .05.54.12 1ZM54.8 173.54c-.08-.23-.15-.49-.22-.75.07.26.14.52.22.75Z"
        fill="none"
      />
      <path
        d="M54.63 173.57a3.13 3.13 0 0 0 .63 1l1.8 1.8-1.8-1.8a3.13 3.13 0 0 1-.63-1ZM53.94 170.72l-.12-1ZM54.51 173.24a6.65 6.65 0 0 1-.23-.75 6.65 6.65 0 0 0 .23.75Z"
        fill="none"
      />
      <path
        d="M54 173a3.13 3.13 0 0 0 .63 1l1.79 1.8-1.75-1.8a3.13 3.13 0 0 1-.67-1ZM53.91 172.65c-.08-.23-.15-.49-.22-.75.07.26.14.52.22.75Z"
        fill="none"
      />
      <path d="M55.28 142.93c8.4 0 10 8.38 10 17.39 0 10-1.55 18.5-9.75 18.5s-9.71-7.78-9.71-18.21c-.03-8.56 1.39-17.68 9.46-17.68Zm.25 31.45c3.12 0 2.53-9.94 2.53-13.84 0-4.8.15-13.06-2.54-13.06S53 155.4 53 160c0 4-.3 14.43 2.55 14.43" />
      <path
        d="M55.51 177.76a6.87 6.87 0 0 1-7-4.55c-1.46-3.41-1.65-8.52-1.65-12.59 0-11.33 2.68-16.61 8.41-16.61a7.17 7.17 0 0 1 7.16 4.68c1.19 2.66 1.74 6.35 1.74 11.64.01 12.22-2.58 17.43-8.66 17.43Zm0-31.34a2.38 2.38 0 0 0-1.72.73c-1.77 1.78-1.91 6.53-1.9 12.1V161c0 5.9.1 11.83 2 13.75a2.28 2.28 0 0 0 1.63.7 2.45 2.45 0 0 0 1.79-.77c1.85-1.87 1.92-7 1.83-12.48v-2.38c0-5.87-.13-10.89-1.93-12.69a2.38 2.38 0 0 0-1.68-.71Z"
        fill="#c51f30"
      />
      <path d="M174.74 143.83v8c-1.22-2.08-2.11-3.13-3.77-3.13h-1.38V176a5.73 5.73 0 0 0 .82 2.86h-7.71v-30.11h-1.41c-1.44 0-2.5 1.12-3.53 3.06v-8Z" />
      <path
        d="M163.78 177.76v-29a1.08 1.08 0 0 0-1.07-1.08h-1.42a3.77 3.77 0 0 0-2.45.92v-3.69h14.83v3.77a3.93 3.93 0 0 0-2.7-1h-1.37a1.08 1.08 0 0 0-1.08 1.08V176a5.76 5.76 0 0 0 .27 1.79Z"
        fill="#c51f30"
      />
      <path d="M29.83 137.25v16.35h4.5v-5c0-2.24.13-2.68-.85-4.7h7.71v34.95h-6.88v-20.44h-4.5v20.41H23v-34.38a5.18 5.18 0 0 0-.86-3 36 36 0 0 0 3.86-1.81 35.61 35.61 0 0 0 3.83-2.38Z" />
      <path
        d="M35.4 177.76v-19.34a1.07 1.07 0 0 0-1.07-1.07h-4.5a1.07 1.07 0 0 0-1.08 1.07v19.34h-4.7v-33.31a5.39 5.39 0 0 0-.47-2.42c1-.42 2-.91 2.93-1.45.78-.42 1.53-.86 2.24-1.3v14.33a1.08 1.08 0 0 0 1.08 1.08h4.5a1.07 1.07 0 0 0 1.07-1.07v-5.5a8.2 8.2 0 0 0-.31-3.13h5v32.81Z"
        fill="#c51f30"
      />
      <path d="M78.72 142.8a45.65 45.65 0 0 1 7.62.71v7.56c-1.18-2.14-2.39-3.61-5.08-3.61a3.83 3.83 0 0 0-3.85 2.86c-.65 1.87-.71 7.22-.72 12.4 0 4.73-.26 11.57 4.89 11.57a5.8 5.8 0 0 0 4.59-2.45v6.26a23.73 23.73 0 0 1-5.82.72c-2.75 0-5.8-.7-7.53-2.69-2.7-3.11-3.41-8.73-3.41-13.38 0-10.17 1.05-19.95 9.31-19.95Z" />
      <path
        d="M80.35 177.76c-3 0-5.43-.85-6.71-2.32-2-2.34-3.16-6.85-3.16-12.68 0-4.25.17-9.63 1.5-13.44s3.44-5.44 6.75-5.44a43 43 0 0 1 6.54.55v3.41a5.72 5.72 0 0 0-4-1.44 4.91 4.91 0 0 0-4.88 3.6c-.64 1.86-.77 6.26-.77 12.76v.65c0 3.68 0 8.25 2.29 10.57a5 5 0 0 0 3.67 1.43 6.13 6.13 0 0 0 3.52-1.07v2.94a22.64 22.64 0 0 1-4.75.48Z"
        fill="#c51f30"
      />
      <path d="M9.32 142.8a45.43 45.43 0 0 1 7.61.71v7.56c-1.19-2.14-2.39-3.61-5.08-3.61A3.82 3.82 0 0 0 8 150.32c-.65 1.87-.72 7.22-.72 12.4 0 4.73-.26 11.57 4.89 11.57a5.8 5.8 0 0 0 4.59-2.45v6.26a23.79 23.79 0 0 1-5.82.72c-2.75 0-5.81-.7-7.53-2.69C.71 173 0 167.4 0 162.75c0-10.17 1.06-19.95 9.32-19.95Z" />
      <path
        d="M11 177.76c-3 0-5.44-.85-6.71-2.32-2-2.34-3.16-6.85-3.16-12.68 0-4.25.17-9.63 1.5-13.44s3.44-5.44 6.75-5.44a42.92 42.92 0 0 1 6.53.55v3.41a5.68 5.68 0 0 0-4-1.44A4.91 4.91 0 0 0 7 150c-.64 1.86-.77 6.26-.77 12.76v.65c0 3.68 0 8.25 2.29 10.57a5 5 0 0 0 3.67 1.43 6.15 6.15 0 0 0 3.52-1.07v2.94a22.64 22.64 0 0 1-4.71.48Z"
        fill="#c51f30"
      />
      <path d="M196.1 143.87v6.46c-1.82-2-2.2-2.1-4.35-2.1h-4.18v5.81h7.32v4.78h-7.31v15.45h5.25c1.81 0 2.1-.66 3.35-2v6.53H180.7v-31.67a5.31 5.31 0 0 0-.85-3.26Z" />
      <path
        d="M181.79 177.76v-30.62a6.73 6.73 0 0 0-.26-2.19H195v2.91a5.15 5.15 0 0 0-3.27-.69h-4.18a1.08 1.08 0 0 0-1.08 1.08v5.81a1.08 1.08 0 0 0 1.08 1.08h6.24v2.62h-6.24a1.08 1.08 0 0 0-1.08 1.08v15.45a1.07 1.07 0 0 0 1.08 1.07h5.25a4.13 4.13 0 0 0 2.27-.54v2.91Z"
        fill="#c51f30"
      />
      <path d="M123.11 144v30.3h3.48c1.8 0 2.1-.66 3.35-2v6.53h-13.69v-31.42a6 6 0 0 0-.86-3.45Z" />
      <path
        d="M117.33 177.76v-30.34A7.6 7.6 0 0 0 117 145h5v29.28a1.07 1.07 0 0 0 1.07 1.07h3.48a4.11 4.11 0 0 0 2.27-.54v2.91Z"
        fill="#c51f30"
      />
      <path d="M155.38 180.05a13.29 13.29 0 0 0 1.91 5.15 5.82 5.82 0 0 1-.95.07 8 8 0 0 1-3.08-.61 23.12 23.12 0 0 0-2.91-1.44 3.63 3.63 0 0 0-1-.13 5.88 5.88 0 0 0-1.54.21 7.47 7.47 0 0 0 .13-3.65c-.25-1.39-1.62-9.43-1.69-9.86h-5.31c-.13 1-1.13 5.93-1.13 7a4.28 4.28 0 0 0 .59 2h-5.86l5.82-30.49a12 12 0 0 0 .39-2.81 3.48 3.48 0 0 0-.48-1.67h8.51c.31 1.76 6.38 35.05 6.6 36.23Zm-13.68-15h3.78l-1.64-11-2.14 11" />
      <path
        d="M155.48 184.15a6.65 6.65 0 0 1-1.78-.47 9.54 9.54 0 0 1-1.11-.59 8.57 8.57 0 0 0-1.91-.89 4.75 4.75 0 0 0-1.32-.17h-.21a9.48 9.48 0 0 0-.15-2.56c-.24-1.37-1.67-9.76-1.69-9.85a1.07 1.07 0 0 0-1.06-.89h-5.31a1.06 1.06 0 0 0-1.06.94c0 .33-.21 1.2-.37 2.12a42.63 42.63 0 0 0-.78 5 4.12 4.12 0 0 0 .11 1h-3l5.57-29.22v-.17a11.34 11.34 0 0 0 .37-2.91c0-.19 0-.36-.05-.53h6.12c1 5.56 6.25 34.27 6.46 35.35a18 18 0 0 0 1.17 3.84ZM143.84 153a1.07 1.07 0 0 0-1.05.87l-2.13 11a1.09 1.09 0 0 0 .22.89 1.08 1.08 0 0 0 .83.39h3.77a1.07 1.07 0 0 0 1.07-1.23l-1.64-11a1.08 1.08 0 0 0-1-.92Z"
        fill="#c51f30"
      />
    </svg>
  )
}

export default TaglineMobile
