import * as React from "react"

function TaglineDesktop(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width={1227.54}
      // height={282.71}
      viewBox="0 0 1227.54 282.71"
      {...props}
    >
      <defs>
        <clipPath id="b">
          <path
            d="m144.73 153.88 5.62 5.63a7.77 7.77 0 0 0-1-.82l-5.62-5.62a8.6 8.6 0 0 1 1 .81Z"
            fill="none"
          />
        </clipPath>
        <clipPath id="c">
          <path
            d="m143.8 153.88 5.62 5.63a7.1 7.1 0 0 0-1-.82l-5.62-5.62a7.11 7.11 0 0 1 1 .81Z"
            fill="none"
          />
        </clipPath>
        <clipPath id="d">
          <path
            d="m142 153.88 5.62 5.63a7.16 7.16 0 0 0-1-.82l-5.62-5.62a7.86 7.86 0 0 1 1 .81Z"
            fill="none"
          />
        </clipPath>
        <linearGradient
          id="a"
          x1={35.04}
          y1={159.94}
          x2={35.04}
          y2={263.73}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#bac9d1" />
          <stop offset={0.34} stopColor="#8aa0a8" />
          <stop offset={1} stopColor="#637785" />
        </linearGradient>
      </defs>
      <path d="M204.42 20.73v109.29h-21.48v-100c0-4.25-.93-5.63-2.67-9.3Z" />
      <path
        d="M186.35 126.69V30.05a17.7 17.7 0 0 0-.86-5.94h15.58v102.58Z"
        fill="#c51f30"
      />
      <path
        d="M500.79 117.36a9.72 9.72 0 0 0 2 3.16l5.62 5.62-5.62-5.62a9.72 9.72 0 0 1-2-3.16ZM498.25 105.36c-.11-1-.2-2.13-.29-3.22.09 1.09.18 2.17.29 3.22ZM498.63 108.44ZM500.39 116.32Z"
        fill="none"
      />
      <path
        d="M499.86 116.44a9.84 9.84 0 0 0 2 3.15l5.62 5.62-5.62-5.62a9.84 9.84 0 0 1-2-3.15ZM497.33 104.43ZM497.7 107.52ZM499.46 115.39c-.25-.72-.48-1.51-.7-2.34.24.83.45 1.62.7 2.34Z"
        fill="none"
      />
      <path
        d="M498 114.58a9.88 9.88 0 0 0 1.95 3.16l5.62 5.62-5.57-5.62a9.88 9.88 0 0 1-2-3.16ZM495.47 102.58ZM495.85 105.66ZM497.61 113.54c-.26-.73-.49-1.51-.7-2.35.21.81.44 1.62.7 2.35Z"
        fill="none"
      />
      <path d="M501.89 20.6c26.26 0 31.18 26.2 31.17 54.38 0 31.21-4.85 57.87-30.5 57.87-26.06 0-30.36-24.35-30.35-57 .01-26.74 4.43-55.25 29.68-55.25Zm.78 98.35c9.75 0 7.91-31.09 7.92-43.29 0-15 .47-40.82-7.95-40.82s-7.95 24.75-8 39c0 12.6-.93 45.13 8 45.13" />
      <path
        d="M502.61 129.52c-10.79 0-17.74-4.51-21.87-14.21-4.56-10.68-5.15-26.67-5.15-39.4 0-35.43 8.41-51.91 26.3-51.91 10.8 0 17.91 4.65 22.39 14.64 3.72 8.3 5.45 19.86 5.45 36.39 0 38.19-8.11 54.49-27.12 54.49Zm.05-98a7.5 7.5 0 0 0-5.39 2.3c-5.53 5.55-6 20.42-5.93 37.85v5.48c-.09 18.47.32 37 6.28 43a7.11 7.11 0 0 0 5.1 2.22 7.74 7.74 0 0 0 5.59-2.42c5.77-5.86 6-21.81 5.73-39.05v-7.44c0-18.38-.41-34.06-6-39.69a7.38 7.38 0 0 0-5.38-2.27Z"
        fill="#c51f30"
      />
      <path
        d="M449.75 44.26a14.53 14.53 0 0 0-1.67-.39 14.53 14.53 0 0 1 1.67.39ZM448.52 42.1a14.34 14.34 0 0 1 3.82 2.9 14.34 14.34 0 0 0-3.82-2.9Z"
        fill="none"
      />
      <path d="M410.41 21.1v21.25c-5.74-7.24-8.72-7.24-17.13-7.24-6.16 0-7.7 2.41-7.7 9.43 0 9.17 7.29 10.55 13.54 14.3 8.56 5.13 13.78 16.75 14.46 29.94 1.11 21.39-1.52 41.24-18.39 41.24h-28.75l-.51-22.74c6.57 6.54 9.63 8.15 16.32 8.15 8.49 0 10.05-10.29 10.06-23.56 0-10.33-5.1-16.82-12.11-19.72-10.51-4.36-16.8-12.9-16.79-27.5 0-15.51 6.34-23.55 18.43-23.55Z" />
      <path
        d="M369.77 126.69 369.5 115c3.8 2.78 7.32 3.88 12.79 3.88 13.41 0 13.41-18.19 13.41-26.92 0-10.78-5.17-19.1-14.18-22.84C371.6 65 366.78 57 366.78 44.67c0-6.94 1.34-12.16 4-15.52 2.46-3.14 6.09-4.67 11.1-4.67h25.21v10c-4-2.66-7.9-2.66-13.78-2.66-9.71 0-11 6.15-11 12.79 0 9.4 6.4 12.64 11.55 15.24 1.22.62 2.47 1.25 3.63 2 7.19 4.25 12.11 14.69 12.76 27.15.92 17.7-1.08 28.88-6.11 34.18a11.59 11.59 0 0 1-8.91 3.53Z"
        fill="#c51f30"
      />
      <path d="M349 20.58v25.19c-3.81-6.51-6.62-9.8-11.8-9.8h-4.31v85.09c0 3.56 1 6 2.56 9h-24.12V36.01H307c-4.51 0-7.82 3.49-11 9.57v-25Z" />
      <path
        d="M314.74 126.69V36a3.37 3.37 0 0 0-3.36-3.36H307a11.79 11.79 0 0 0-7.67 2.86V24h46.39v11.74a12.38 12.38 0 0 0-8.44-3.11h-4.31a3.36 3.36 0 0 0-3.4 3.37v85.1a18.59 18.59 0 0 0 .83 5.6Z"
        fill="#c51f30"
      />
      <path d="M598.55 20.6v20.21c-5.69-6.27-6.88-6.55-13.62-6.55h-2.18c-3.7 0-7.29.12-10.88.12v18.17h22.88v14.93h-22.9v68c0 5.41 1.15 11.4 3.39 15.54a18.54 18.54 0 0 1-2.82.2 20.62 20.62 0 0 1-8.71-1.84c-3.18-1.55-4.88-3.21-8.24-4.34a9.66 9.66 0 0 0-2.88-.38 17.8 17.8 0 0 0-4.63.62 25.33 25.33 0 0 0 2.33-11c0-6.78.1-98.61.11-103.44s-.52-6.09-2.67-10.22Z" />
      <path
        d="M570.34 147.76a16.15 16.15 0 0 1-5.09-1.38 33.73 33.73 0 0 1-3-1.69 25.39 25.39 0 0 0-5.65-2.81 12.21 12.21 0 0 0-3.67-.55 31.56 31.56 0 0 0 .77-7c0-6.7.09-102.49.09-103.46A21.41 21.41 0 0 0 553 24h42.2v9.11c-2.85-1.92-5.45-2.16-10.25-2.16h-2.2c-1.88 0-3.76 0-5.57.06s-3.55.05-5.31.05a3.37 3.37 0 0 0-3.36 3.37v18.14a3.37 3.37 0 0 0 3.36 3.37h19.53v8.2h-19.53a3.38 3.38 0 0 0-3.36 3.37s0 58.69 0 68a42.07 42.07 0 0 0 1.83 12.25Z"
        fill="#c51f30"
      />
      <path d="M279.12 20.91v109.11H261l-21.3-69.25c0 7.24-.11 42.72-.11 49.95s-.74 12.87 2.71 19.3h-18.17V30.91c0-4.6-.63-6-2.72-10h24.42l17.63 50.2v-50.2Z" />
      <path
        d="m263.5 126.69-20.58-66.88a3.36 3.36 0 0 0-3.21-2.38 4.21 4.21 0 0 0-.5 0 3.36 3.36 0 0 0-2.86 3.33c0 3.61 0 14.29-.06 25s0 21.37 0 25v3.16c-.07 4.31-.14 8.44 1.12 12.77h-9.79V30.93a20.3 20.3 0 0 0-.86-6.63h16.74l16.86 48a3.37 3.37 0 0 0 3.17 2.25 3.64 3.64 0 0 0 .56 0 3.37 3.37 0 0 0 2.8-3.32V24.3h8.89v102.39Z"
        fill="#c51f30"
      />
      <path d="M127.4 0v51.14h14.06l.11-15.75c.1-7 .41-8.39-2.67-14.7H163V130h-21.57V66.16h-14.05V130h-21.46V22.47c0-4.25-.82-5.63-2.67-9.3a113.66 113.66 0 0 0 12.15-5.74 112.58 112.58 0 0 0 12-7.43Z" />
      <path
        d="M144.83 126.69V66.21a3.36 3.36 0 0 0-3.36-3.36h-14.06a3.36 3.36 0 0 0-3.36 3.36v60.48h-14.73V22.51a17.22 17.22 0 0 0-1.45-7.58c3-1.31 6.1-2.84 9.17-4.51 2.42-1.31 4.78-2.69 7-4.09v44.84a3.36 3.36 0 0 0 3.36 3.36h14.06a3.36 3.36 0 0 0 3.36-3.34l.11-15.76v-1.56c.08-4.17.12-6.49-1-9.8h15.68v102.62Z"
        fill="#c51f30"
      />
      <path
        d="M827.29 117.36a9.71 9.71 0 0 0 2 3.16l5.62 5.62-5.62-5.62a9.71 9.71 0 0 1-2-3.16ZM824.75 105.36c-.11-1-.2-2.13-.29-3.22.09 1.09.18 2.17.29 3.22ZM825.13 108.44c-.14-1-.27-2-.38-3.08.11 1.05.25 2.08.38 3.08ZM826.89 116.32Z"
        fill="none"
      />
      <path
        d="M826.36 116.44a9.67 9.67 0 0 0 2 3.15l5.62 5.62-5.62-5.62a9.67 9.67 0 0 1-2-3.15ZM823.82 104.43ZM824.2 107.52ZM826 115.39c-.25-.72-.49-1.51-.7-2.34.17.83.41 1.62.7 2.34Z"
        fill="none"
      />
      <path
        d="M824.51 114.58a9.71 9.71 0 0 0 2 3.16l5.62 5.62-5.62-5.62a9.71 9.71 0 0 1-2-3.16ZM822 102.58c-.11-1.06-.21-2.13-.29-3.23.05 1.1.15 2.17.29 3.23ZM822.35 105.66ZM824.11 113.54c-.26-.73-.49-1.51-.71-2.35.22.81.45 1.62.71 2.35Z"
        fill="none"
      />
      <path d="M828.38 20.6c26.27 0 31.19 26.2 31.18 54.38 0 31.21-4.85 57.87-30.5 57.87-26.06 0-30.36-24.35-30.35-57 0-26.74 4.43-55.25 29.67-55.25Zm.79 98.35c9.75 0 7.91-31.09 7.91-43.29 0-15 .48-40.82-7.94-40.82s-8 24.75-8 39c0 12.6-.94 45.13 8 45.13" />
      <path
        d="M829.1 129.52c-10.78 0-17.73-4.51-21.87-14.21-4.55-10.68-5.14-26.67-5.14-39.4 0-35.43 8.36-51.93 26.3-51.93 10.8 0 17.91 4.65 22.38 14.64 3.72 8.3 5.46 19.86 5.46 36.39 0 38.21-8.12 54.51-27.13 54.51Zm.06-98a7.5 7.5 0 0 0-5.39 2.3c-5.53 5.55-6 20.42-5.93 37.85v5.48c-.09 18.47.31 37 6.27 43a7.14 7.14 0 0 0 5.11 2.22 7.74 7.74 0 0 0 5.59-2.42c5.76-5.86 6-21.81 5.73-39.05 0-2-.06-3.72-.06-5.19v-2.25c0-18.38-.41-34.06-6-39.69a7.38 7.38 0 0 0-5.32-2.27Z"
        fill="#c51f30"
      />
      <path d="M990.52 20.6v20.21c-5.69-6.27-6.89-6.55-13.62-6.55h-2.18c-3.7 0-7.29.12-10.88.12v18.17h22.89v14.93h-22.9v68c0 5.41 1.16 11.4 3.4 15.54a18.67 18.67 0 0 1-2.83.2 20.61 20.61 0 0 1-8.7-1.84c-3.18-1.55-4.88-3.21-8.24-4.34a9.72 9.72 0 0 0-2.89-.38 17.71 17.71 0 0 0-4.62.62 25.33 25.33 0 0 0 2.32-11c0-6.78.11-98.61.12-103.44s-.52-6.09-2.67-10.22Z" />
      <path
        d="M962.31 147.76a16.23 16.23 0 0 1-5.09-1.38c-1.11-.54-2-1.1-3-1.69a25.27 25.27 0 0 0-5.66-2.81 12.1 12.1 0 0 0-3.66-.55 31.56 31.56 0 0 0 .77-7c0-6.7.08-102.49.08-103.46A21.45 21.45 0 0 0 945 24h42.22v9.11c-2.85-1.92-5.45-2.16-10.25-2.16h-2.19c-1.89 0-3.76 0-5.57.06s-3.55.05-5.31.05a3.36 3.36 0 0 0-3.36 3.37v18.14a3.36 3.36 0 0 0 3.36 3.37h19.52v8.2h-19.57a3.36 3.36 0 0 0-3.36 3.37s0 58.69 0 68a42.41 42.41 0 0 0 1.82 12.25Z"
        fill="#c51f30"
      />
      <path d="M758.39 20.6a140.72 140.72 0 0 1 23.8 2.23v23.64c-3.71-6.7-7.48-11.29-15.9-11.29-5.64 0-9.89 2.77-12 8.93-2 5.85-2.24 22.6-2.24 38.78 0 14.82-.84 36.21 15.28 36.21 6.26 0 10.52-3.15 14.36-7.67V131a75 75 0 0 1-18.22 2.26c-8.59 0-18.15-2.2-23.53-8.41-8.46-9.74-10.68-27.33-10.67-41.85-.03-31.81 3.26-62.4 29.12-62.4Z" />
      <path
        d="M763.48 129.93c-9.35 0-17-2.64-21-7.25-6.36-7.32-9.87-21.41-9.87-39.65 0-13.31.54-30.12 4.69-42 4.05-11.62 10.75-17 21.1-17a134.06 134.06 0 0 1 20.45 1.72v10.6a18 18 0 0 0-12.55-4.51c-7.3 0-12.7 4-15.21 11.2-2 5.8-2.42 19.56-2.42 39.88v2c0 11.49-.06 25.79 7.17 33.05a15.61 15.61 0 0 0 11.49 4.47 19.12 19.12 0 0 0 11-3.35v9.22a70.92 70.92 0 0 1-14.85 1.62Z"
        fill="#c51f30"
      />
      <path d="M1056.88 20.6v20.21c-5.69-6.27-6.88-6.55-13.61-6.55h-2.18c-3.7 0-7.29.12-10.88.12v18.17h22.87v14.93h-22.88v48.32h16.41c5.64 0 6.57-2.07 10.47-6.32v20.44h-48.34v-99.1c0-4.94-.51-6.09-2.66-10.22Z" />
      <path
        d="M1012.13 126.6V30.84a21.3 21.3 0 0 0-.82-6.86h42.23v9.1c-2.85-1.92-5.45-2.16-10.25-2.16h-2.2c-1.88 0-3.76 0-5.57.06s-3.55.06-5.31.06a3.36 3.36 0 0 0-3.36 3.36v18.18a3.37 3.37 0 0 0 3.36 3.36h19.53v8.21h-19.53a3.36 3.36 0 0 0-3.36 3.36v48.32a3.36 3.36 0 0 0 3.36 3.36h16.42a12.94 12.94 0 0 0 7.11-1.68v9.09Z"
        fill="#c51f30"
      />
      <path d="M1124.08 20.6v20.21c-5.69-6.27-6.88-6.55-13.61-6.55h-2.19c-3.69 0-7.28.12-10.87.12v18.17h22.88v14.93h-22.88v48.32h16.42c5.64 0 6.57-2.07 10.47-6.32v20.44h-48.33v-99.1c0-4.94-.51-6.09-2.66-10.22Z" />
      <path
        d="M1079.32 126.6V30.84a21.34 21.34 0 0 0-.81-6.86h42.22v9.1c-2.85-1.92-5.45-2.16-10.25-2.16h-2.19c-1.89 0-3.76 0-5.57.06s-3.55.06-5.31.06a3.35 3.35 0 0 0-3.36 3.36v18.18a3.36 3.36 0 0 0 3.36 3.36h19.52v8.21h-19.52a3.35 3.35 0 0 0-3.36 3.36v48.32a3.35 3.35 0 0 0 3.36 3.36h16.42a12.94 12.94 0 0 0 7.11-1.68v9.09Z"
        fill="#c51f30"
      />
      <path d="M924.18 20.6v20.21c-5.69-6.27-6.89-6.55-13.61-6.55h-2.19c-3.69 0-7.29.12-10.87.12v18.17h22.88v14.93h-22.9v62.44H876v-99.1c0-4.94-.51-6.09-2.67-10.22Z" />
      <path
        d="M879.42 126.6V30.84a21.34 21.34 0 0 0-.81-6.86h42.22v9.1c-2.85-1.92-5.45-2.16-10.25-2.16h-2.19c-1.89 0-3.76 0-5.57.06s-3.55.06-5.31.06a3.35 3.35 0 0 0-3.36 3.36v18.18a3.36 3.36 0 0 0 3.36 3.36H917v8.21h-19.49a3.35 3.35 0 0 0-3.36 3.36v59.09Z"
        fill="#c51f30"
      />
      <path d="M212.83 153.07v109.29h-21.48v-100c0-4.25-.93-5.62-2.67-9.3Z" />
      <path
        d="M194.75 259v-96.61a17.77 17.77 0 0 0-.85-5.94h15.58V259Z"
        fill="#c51f30"
      />
      <path d="M259 153.07a141.88 141.88 0 0 1 23.81 2.23v23.64c-3.72-6.7-7.49-11.28-15.9-11.28-5.64 0-9.89 2.76-12 8.93-2 5.85-2.23 22.6-2.24 38.77 0 14.82-.83 36.21 15.28 36.21 6.26 0 10.52-3.15 14.37-7.67v19.57a74.88 74.88 0 0 1-18.32 2.26c-8.6 0-18.15-2.2-23.54-8.41-8.45-9.74-10.67-27.32-10.67-41.84.04-31.82 3.34-62.41 29.21-62.41Z" />
      <path
        d="M264.08 262.41c-9.35 0-17-2.64-21-7.25-6.36-7.33-9.86-21.41-9.86-39.65 0-13.32.53-30.12 4.69-42 4-11.61 10.75-17 21.09-17a134 134 0 0 1 20.45 1.72v10.65a18 18 0 0 0-12.54-4.5c-7.31 0-12.71 4-15.21 11.19-2 5.8-2.42 19.56-2.42 39.88v2.05c0 11.49-.06 25.79 7.18 33.05a15.59 15.59 0 0 0 11.46 4.45 19.16 19.16 0 0 0 11-3.36v9.22a71 71 0 0 1-14.84 1.55Z"
        fill="#c51f30"
      />
      <path d="M357.94 153v109.32h-21.58v-63.83h-14.04v63.83h-21.47v-100c0-4.25-.82-5.63-2.66-9.3h24.11v30.45h14.07l.1-15.76c.11-7 .42-8.38-2.66-14.69Z" />
      <path
        d="M339.77 259v-60.44a3.36 3.36 0 0 0-3.36-3.36h-14.07a3.35 3.35 0 0 0-3.36 3.36V259h-14.73v-96.64a18.47 18.47 0 0 0-.81-5.94H319v27.09a3.36 3.36 0 0 0 3.36 3.37h14.07a3.36 3.36 0 0 0 3.36-3.34l.1-15.76v-1.56c.07-4.17.12-6.5-1-9.8h15.71V259Z"
        fill="#c51f30"
      />
      <path
        fill="none"
        d="m10.64 263.31 5.62 5.62-5.62-5.62zM9.71 262.38l5.62 5.62-5.62-5.62zM7.86 260.53l5.62 5.62-5.62-5.62z"
      />
      <path d="M51.23 156.56v19.87c-4.65-5-7.43-5.59-12-5.59-1.56 0-3.34.07-5.47.07-6 0-9.75 2.79-9.75 11.29 0 7.35 2.56 9.32 8 9.32v10.69l-1.75-.12H30c-8.88 0-7.48 24.12-7.49 32.07 0 4.71-.72 18.78 5.13 18.78 5.54 0 4.42-25.76 4.42-29.77v-11.73h28.08V225h-6.26c0 2.17.09 18 .09 20.21 0 18.65-13.29 21.84-27.44 21.84C8.57 267.05 0 256.43 0 235c0-8.84 1.34-32.74 9.66-36.07-6.46-4.6-7.39-11.28-7.39-19.55 0-10.91 2.37-22.77 13.46-22.77Z" />
      <path
        d="M26.57 263.73c-8 0-13.67-2.21-17.41-6.77s-5.77-12-5.77-22c0-18.75 3.83-31.48 7.54-33a3.35 3.35 0 0 0 .69-5.86c-5-3.57-6-8.66-6-16.81 0-19.41 7.59-19.41 10.08-19.41h32.18v9.43a16.73 16.73 0 0 0-8.61-1.87H33.8c-6 0-13.12 2.55-13.12 14.65 0 7.29 2.57 11.3 8 12.38v4.25a8 8 0 0 0-4.38 2.33c-5.14 5.22-5.39 17.72-5.2 30.08v4.32c-.08 7.47.14 15.08 3.75 18.74a6.61 6.61 0 0 0 4.76 2 5.21 5.21 0 0 0 3.79-1.66c3.18-3.24 4.34-11.82 4-29.63V214.69h21.4v6.83h-2.9a3.36 3.36 0 0 0-3.35 3.48c0 1.1 0 5.61.05 10.13s0 9 0 10.09c.05 13.33-6.7 18.51-24.03 18.51Z"
        fill="#c51f30"
      />
      <path
        d="M35.17 241.25c-.07.95-.16 1.84-.25 2.69l.08 2.78Z"
        fill="url(#a)"
      />
      <g
        clipPath="url(#b)"
        style={{
          isolation: "isolate",
        }}
      >
        <path
          d="m144.73 153.88 5.62 5.63a7.77 7.77 0 0 0-1-.82l-5.62-5.62a8.6 8.6 0 0 1 1 .81"
          fill="#74919d"
        />
      </g>
      <g
        clipPath="url(#c)"
        style={{
          isolation: "isolate",
        }}
      >
        <path
          d="m143.8 153.88 5.62 5.63a7.1 7.1 0 0 0-1-.82l-5.62-5.62a7.11 7.11 0 0 1 1 .81"
          fill="#5e7079"
        />
      </g>
      <g
        clipPath="url(#d)"
        style={{
          isolation: "isolate",
        }}
      >
        <path
          d="m142 153.88 5.62 5.63a7.16 7.16 0 0 0-1-.82l-5.62-5.62a7.86 7.86 0 0 1 1 .81"
          fill="#495b64"
        />
      </g>
      <path d="M176.65 266.38a38.59 38.59 0 0 0 6.64 16.12 19.32 19.32 0 0 1-2.85.21 22.61 22.61 0 0 1-8.94-1.92c-3.68-1.6-4.72-3.33-8.53-4.49a11.79 11.79 0 0 0-3.33-.4 21.41 21.41 0 0 0-5.27.64 28.5 28.5 0 0 0 1.28-11.42 384.79 384.79 0 0 0-6.3-42.43c-1.87-9-4.8-17-8.37-19.5v58.91h-21.46v-99.61c0-4-.82-6-2.67-9.42h33c15.6 0 21.94 7.29 22.49 23.92.28 8.38-2.32 18-11.78 21.13 4 3.44 6.64 13.09 8.61 22.55 3.9 18.47 5.77 34.21 7.48 45.71Zm-35.23-79c6.85 0 9.29-2.62 8.88-9.88-.38-6.76-2.21-10.8-7.35-10.8h-2v20.68h.43" />
      <path
        d="M177.34 279.1a19.61 19.61 0 0 1-7.73-3.21 20.39 20.39 0 0 0-5.61-2.77 15 15 0 0 0-4.32-.55h-.87a31.84 31.84 0 0 0 .23-7.74 391.34 391.34 0 0 0-6.37-42.83c-1.64-7.9-4.66-18.05-9.75-21.58a3.36 3.36 0 0 0-3.47-.22 3.37 3.37 0 0 0-1.81 3v55.56h-14.72v-96.25a19.69 19.69 0 0 0-.83-6.06h27.82c13.45 0 18.64 5.6 19.14 20.67.42 12.62-5.76 16.59-9.48 17.84a3.37 3.37 0 0 0-1.14 5.73c2.6 2.26 5.14 9.22 7.53 20.7 3 14.37 4.8 27.17 6.25 37.45.41 2.88.79 5.58 1.16 8.07a41.51 41.51 0 0 0 3.97 12.19ZM141 163.39a3.37 3.37 0 0 0-3.37 3.37v20.67a3.36 3.36 0 0 0 3.31 3.36h.5c2.79 0 6.82-.33 9.52-3.19s2.88-7.26 2.71-10.25c-.53-9.27-4.14-14-10.72-14Z"
        fill="#c51f30"
      />
      <path
        d="M929.59 247.06a10 10 0 0 0 2 3.15l5.62 5.62-5.62-5.62a10 10 0 0 1-2-3.15ZM927.06 235.05c-.11-1.05-.21-2.13-.29-3.22.08 1.09.23 2.17.29 3.22ZM927.43 238.13ZM929.19 246c-.25-.72-.48-1.51-.7-2.34.22.84.45 1.63.7 2.34Z"
        fill="none"
      />
      <path
        d="M928.67 246.13a9.66 9.66 0 0 0 2 3.15l5.62 5.63-5.62-5.63a9.66 9.66 0 0 1-2-3.15ZM926.13 234.12c-.11-1.05-.21-2.13-.29-3.22.08 1.1.16 2.17.29 3.22ZM926.51 237.21ZM928.27 245.08c-.26-.72-.49-1.5-.71-2.34.22.84.44 1.62.71 2.34Z"
        fill="none"
      />
      <path
        d="M926.81 244.27a9.72 9.72 0 0 0 2 3.16l5.62 5.62-5.62-5.62a9.72 9.72 0 0 1-2-3.16ZM924.27 232.27ZM924.65 235.35ZM926.41 243.23Z"
        fill="none"
      />
      <path d="M930.69 150.29c26.26 0 31.18 26.2 31.17 54.38 0 31.21-4.84 57.87-30.5 57.87-26.06 0-30.36-24.35-30.35-57-.01-26.74 4.43-55.25 29.68-55.25Zm.78 98.35c9.75 0 7.92-31.09 7.92-43.29 0-15 .47-40.82-7.94-40.82s-8 24.75-8 39c0 12.61-.94 45.13 8 45.13" />
      <path
        d="M931.41 259.22c-10.79 0-17.74-4.52-21.87-14.21-4.56-10.69-5.14-26.67-5.14-39.4 0-35.44 8.35-51.94 26.3-51.94 10.79 0 17.9 4.65 22.38 14.64 3.72 8.3 5.45 19.86 5.45 36.39 0 38.21-8.11 54.52-27.12 54.52Zm.05-98a7.53 7.53 0 0 0-5.39 2.31c-5.53 5.54-6 20.41-5.92 37.84v5.48c-.1 18.48.31 37 6.27 43a7.11 7.11 0 0 0 5.1 2.22 7.72 7.72 0 0 0 5.59-2.42c5.77-5.85 6-21.81 5.74-39.05 0-2-.06-3.72-.06-5.19v-2.22c0-18.39-.41-34.07-6-39.7a7.34 7.34 0 0 0-5.33-2.3Z"
        fill="#c51f30"
      />
      <path
        d="M797.93 218.88ZM797 218c-.11-1.06-.2-2.13-.29-3.23.09 1.05.18 2.12.29 3.23ZM795.15 216.09c-.11-1.05-.21-2.13-.29-3.22.08 1.13.14 2.13.29 3.22ZM795.52 219.18ZM785.82 247.06a9.77 9.77 0 0 0 2 3.15l5.61 5.62-5.61-5.62a9.77 9.77 0 0 1-2-3.15ZM783.66 238.13ZM785.42 246Z"
        fill="none"
      />
      <path
        d="M784.89 246.13a9.84 9.84 0 0 0 2 3.15l5.62 5.63-5.62-5.63a9.84 9.84 0 0 1-2-3.15ZM782.73 237.21ZM784.49 245.08Z"
        fill="none"
      />
      <path
        d="M783 244.27a9.71 9.71 0 0 0 2 3.16l5.62 5.62-5.62-5.62a9.71 9.71 0 0 1-2-3.16ZM782.64 243.23Z"
        fill="none"
      />
      <path d="M786.92 150.29c26.26 0 31.18 26.2 31.17 54.38 0 31.21-4.85 57.87-30.5 57.87-26.06 0-30.36-24.35-30.35-57 .01-26.74 4.43-55.25 29.68-55.25Zm.78 98.35c9.75 0 7.91-31.09 7.92-43.29 0-15 .47-40.82-8-40.82s-7.95 24.75-8 39c0 12.61-.93 45.13 8 45.13" />
      <path
        d="M787.64 259.22c-10.79 0-17.74-4.52-21.87-14.21-4.56-10.69-5.15-26.67-5.15-39.4 0-35.44 8.36-51.94 26.3-51.94 10.8 0 17.91 4.65 22.39 14.64 3.72 8.3 5.45 19.86 5.45 36.39 0 38.21-8.11 54.52-27.12 54.52Zm.05-98a7.51 7.51 0 0 0-5.39 2.31c-5.53 5.54-6 20.41-5.93 37.84v5.48c-.09 18.48.32 37 6.28 43a7.1 7.1 0 0 0 5.1 2.22 7.74 7.74 0 0 0 5.59-2.42c5.77-5.85 6-21.81 5.73-39.05v-7.41c0-18.39-.41-34.07-6-39.7a7.38 7.38 0 0 0-5.38-2.3Z"
        fill="#c51f30"
      />
      <path d="M1160.56 153.1v25.19c-3.81-6.51-6.62-9.79-11.8-9.79h-4.31v85.08c0 3.56 1 6 2.56 9h-24.12v-94h-4.41c-4.51 0-7.82 3.48-11 9.56v-25Z" />
      <path
        d="M1126.27 259.21v-90.69a3.36 3.36 0 0 0-3.36-3.36h-4.42a11.78 11.78 0 0 0-7.67 2.85v-11.53h46.39v11.78a12.37 12.37 0 0 0-8.44-3.1h-4.31a3.35 3.35 0 0 0-3.36 3.36v85.1a18.52 18.52 0 0 0 .83 5.59Z"
        fill="#c51f30"
      />
      <path d="M707.3 132.52v51.14h14.06l.11-15.75c.1-7 .41-8.38-2.66-14.7h24.11v109.33h-21.59v-63.83h-14.05v63.83h-21.46V155.02c0-4.25-.82-5.64-2.67-9.31a114.51 114.51 0 0 0 12.18-5.71 112.55 112.55 0 0 0 11.97-7.48Z" />
      <path
        d="M724.74 259.21v-60.47a3.37 3.37 0 0 0-3.36-3.37h-14.07a3.36 3.36 0 0 0-3.36 3.37v60.47h-14.73V155a17.05 17.05 0 0 0-1.45-7.58c3-1.31 6.1-2.84 9.17-4.51 2.43-1.32 4.79-2.7 7-4.1v44.84a3.35 3.35 0 0 0 3.36 3.36h14.07a3.36 3.36 0 0 0 3.36-3.34l.1-15.75v-1.57c.07-4.16.12-6.49-1-9.8h15.69v102.66Z"
        fill="#c51f30"
      />
      <path d="M860.24 149.88a141.88 141.88 0 0 1 23.81 2.23v23.64c-3.72-6.7-7.49-11.29-15.9-11.29-5.64 0-9.9 2.77-12 8.93-2 5.85-2.23 22.6-2.24 38.78 0 14.82-.83 36.21 15.28 36.21 6.26 0 10.52-3.15 14.37-7.67v19.57a74.88 74.88 0 0 1-18.21 2.26c-8.6 0-18.15-2.2-23.54-8.41-8.45-9.74-10.67-27.33-10.67-41.85-.05-31.81 3.25-62.4 29.1-62.4Z" />
      <path
        d="M865.34 259.22c-9.35 0-17-2.65-21-7.26-6.36-7.32-9.86-21.41-9.86-39.65 0-13.31.53-30.12 4.69-42 4-11.61 10.75-17 21.09-17A134 134 0 0 1 880.7 155v10.65a18 18 0 0 0-12.54-4.51c-7.31 0-12.71 4-15.21 11.2-2 5.8-2.42 19.56-2.42 39.88v2.05c0 11.49-.06 25.79 7.18 33.05a15.59 15.59 0 0 0 11.48 4.47 19.18 19.18 0 0 0 11-3.35v9.22a71 71 0 0 1-14.85 1.56Z"
        fill="#c51f30"
      />
      <path d="M643.17 149.88a141.78 141.78 0 0 1 23.83 2.23v23.64c-3.71-6.7-7.48-11.29-15.9-11.29-5.64 0-9.89 2.77-12 8.93-2 5.85-2.24 22.6-2.25 38.78 0 14.82-.83 36.21 15.28 36.21 6.26 0 10.53-3.15 14.37-7.67v19.57a75 75 0 0 1-18.22 2.26c-8.6 0-18.15-2.2-23.53-8.41-8.52-9.74-10.75-27.33-10.75-41.85 0-31.81 3.32-62.4 29.17-62.4Z" />
      <path
        d="M648.27 259.22c-9.35 0-17-2.65-21-7.26-6.36-7.32-9.86-21.41-9.86-39.65 0-13.31.54-30.12 4.69-42 4-11.61 10.75-17 21.1-17a133.94 133.94 0 0 1 20.43 1.69v10.65a17.94 17.94 0 0 0-12.54-4.51c-7.3 0-12.71 4-15.21 11.2-2 5.8-2.42 19.56-2.42 39.88v2.05c0 11.49-.07 25.79 7.17 33.05a15.61 15.61 0 0 0 11.49 4.47 19.12 19.12 0 0 0 11-3.35v9.22a70.93 70.93 0 0 1-14.85 1.56Z"
        fill="#c51f30"
      />
      <path d="M1227.36 153.21v20.22c-5.69-6.27-6.88-6.55-13.61-6.55h-2.18c-3.7 0-7.29.11-10.88.11v18.18h22.88v14.93h-22.88v48.32h16.42c5.64 0 6.57-2.07 10.47-6.32v20.44h-48.38v-99.1c0-4.95-.51-6.09-2.66-10.23Z" />
      <path
        d="M1182.6 259.22v-95.76a21.41 21.41 0 0 0-.81-6.87H1224v9.11c-2.85-1.92-5.44-2.16-10.25-2.16h-2.19c-1.89 0-3.76 0-5.57.06s-3.55.05-5.31.05a3.36 3.36 0 0 0-3.36 3.37v18.17a3.36 3.36 0 0 0 3.36 3.37h19.53v8.2h-19.53a3.36 3.36 0 0 0-3.36 3.37v48.32a3.35 3.35 0 0 0 3.36 3.36h16.42a12.94 12.94 0 0 0 7.11-1.68v9.09Z"
        fill="#c51f30"
      />
      <path d="M999.07 153.5v94.91h10.88c5.64 0 6.56-2.06 10.46-6.31v20.44H977.6v-98.25a18.86 18.86 0 0 0-2.6-10.79Z" />
      <path
        d="M981 259.22v-94.91a23.36 23.36 0 0 0-.92-7.43h15.64v91.57a3.35 3.35 0 0 0 3.36 3.36H1010a13 13 0 0 0 7.1-1.68v9.09Z"
        fill="#c51f30"
      />
      <path d="M1100 266.38c1.06 5.6 2.86 11.82 6 16.12a20.3 20.3 0 0 1-3 .21 24.89 24.89 0 0 1-9.63-1.92c-3.66-1.6-5.3-3.33-9.09-4.49a11 11 0 0 0-3.15-.4 18.9 18.9 0 0 0-4.84.64 23.27 23.27 0 0 0 .42-11.42c-.77-4.33-5-29.5-5.28-30.82h-16.6c-.41 3.22-3.56 18.53-3.56 21.86a13.5 13.5 0 0 0 1.85 6.32h-18.32l18.2-95.37c.73-3.53 1.38-6.5 1.22-8.78a10.53 10.53 0 0 0-1.5-5.23h26.63c.97 5.48 19.94 109.6 20.65 113.28Zm-42.77-46.86H1069l-5.12-34.44-6.67 34.44" />
      <path
        d="M1100.31 279.21a20.26 20.26 0 0 1-5.56-1.46 30.57 30.57 0 0 1-3.49-1.85 26.45 26.45 0 0 0-6-2.78 14.36 14.36 0 0 0-4.14-.55h-.65a31.34 31.34 0 0 0-.46-8c-.77-4.27-5.24-30.54-5.29-30.8a3.35 3.35 0 0 0-3.31-2.8h-16.61a3.37 3.37 0 0 0-3.34 2.93c-.13 1-.63 3.77-1.16 6.65-1.41 7.72-2.41 13.36-2.41 15.65a13.26 13.26 0 0 0 .33 2.95h-9.38l17.4-91.39.11-.52c.72-3.49 1.34-6.5 1.17-9.11q-.06-.87-.18-1.65h19.15c3.17 17.4 19.56 107.19 20.2 110.56a57 57 0 0 0 3.62 12.17Zm-36.4-97.47a3.37 3.37 0 0 0-3.3 2.73l-6.67 34.44a3.36 3.36 0 0 0 3.3 4H1069a3.37 3.37 0 0 0 3.33-3.86l-5.13-34.44a3.37 3.37 0 0 0-3.25-2.87Z"
        fill="#c51f30"
      />
    </svg>
  )
}

export default TaglineDesktop
