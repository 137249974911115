import * as React from "react"

function BaconBrittleMobile(props) {
  return (
    <svg
      style={{
        postion: `absolute`,
      }}
      xmlns="http://www.w3.org/2000/svg"
      // width={199.64}
      // height={91.13}
      viewBox="0 0 199.64 91.13"
      {...props}
    >
      <path
        d="M46.95 24.64V.58c3.5.14 7 .27 10.49.38 7.51.26 12.55 5.15 12.55 12.27s-5.04 11.73-12.56 11.62c-3.49-.05-7-.11-10.48-.21Zm7.6-5.21 2.64.05c3.12.06 5.13-2.49 5.13-6.49s-2-6.63-5.13-6.72l-2.64-.08Z"
        fill="#fff"
      />
      <path
        d="M57.43 1.5c7.16.24 12 4.92 12 11.72s-4.82 11.2-12 11.1c-3.3-.05-6.61-.11-9.91-.18v-23c3.3.13 6.61.25 9.91.36m-3.45 18.48h3.21c3.41.06 5.7-2.77 5.7-7s-2.29-7.17-5.7-7.27l-2.06-.01-1.15-.06v14.34M57.43.43C53.74.31 50.06.17 46.37.01V25.2l11.06.19c7.85.1 13.13-4.8 13.13-12.15S65.29.7 57.43.43Zm-2.3 18.5V6.77l2.06.06c2.78.08 4.55 2.51 4.55 6.18s-1.77 6-4.55 6h-2.06Z"
        fill="#c51f30"
      />
      <path
        d="M88.99 25.15c-.25-.93-.51-1.86-.76-2.79h-8.06c-.24.93-.49 1.86-.73 2.79l-8.28-.07q4-11.8 8-23.53c3.39 0 6.79.09 10.18.12l8 23.55Zm-7.56-7.77h5.47L84.16 7.06q-1.35 5.16-2.73 10.32Z"
        fill="#fff"
      />
      <path
        d="M88.89 2.14q3.8 11.28 7.6 22.51h-7l-.54-2c-.08-.26-.15-.53-.22-.8h-.9l-7.16-.05h-.91l-.21.81-.51 2-7-.07q3.71-11.3 7.51-22.51c3.11.05 6.23.09 9.34.11M80.72 17.9h6.92c-.11-.44-.23-.88-.34-1.31-.68-2.53-1.36-5.07-2-7.6-.38-1.41-.75-2.82-1.13-4.23-.36 1.41-.73 2.81-1.1 4.21l-2 7.57-.34 1.3m9-16.8q-5.51 0-11-.14-4.18 12.33-8.3 24.65l9.51.08.72-2.79h7.16c.26.94.51 1.87.76 2.8h9.51L89.73 1.1Zm-7.55 15.82 2-7.57c.68 2.54 1.35 5.07 2 7.6h-4Z"
        fill="#c51f30"
      />
      <path
        d="m116.83 25.12-4-5.21c-1-1.37-1.67-2.17-3.07-2.16h-1.16v7.41H101V1.64q6 0 12-.06c6-.07 9.52 2.79 9.52 7.7 0 4.33-2.28 5.92-4.27 6.81l-1.12.5 1.13.46a9.34 9.34 0 0 1 2.45 2.36l4.41 5.63Zm-8.25-12.23h2.57c2.44 0 3.68-1 3.68-3 0-1.49-1.11-3-3.58-3h-2.67Z"
        fill="#fff"
      />
      <path
        d="M112.99 2.12c5.6-.06 8.94 2.61 8.94 7.19 0 4-2 5.45-3.94 6.32l-2.23 1 2.26.91a9.56 9.56 0 0 1 2.21 2.2q1.89 2.38 3.76 4.79l-6.86.11-3.85-5c-1-1.37-1.81-2.36-3.51-2.35h-1.73v7.35h-6.46V2.19c3.81 0 7.63 0 11.44-.07m-5 11.3h3.14c3.71 0 4.25-2.26 4.25-3.59 0-1.75-1.28-3.5-4.15-3.48H108.02v7.05m5-12.35q-6.28.06-12.59.07V25.7h8.76v-7.42h.58c1.12 0 1.59.63 2.6 1.95l4.2 5.43 9.69-.07-5.06-6.46a10.26 10.26 0 0 0-2.68-2.54c2.35-1.05 4.59-2.88 4.59-7.28 0-5.18-3.79-8.3-10.09-8.23Zm-3.83 11.29v-5h2.09c2 0 3 1.07 3 2.43s-.76 2.51-3.11 2.52Z"
        fill="#c51f30"
      />
      <path
        d="M144.43 24.82q-4.37-6-8.73-12 4.08-5.92 8.16-11.95c2.84-.1 5.69-.2 8.53-.32q-4.34 6.06-8.68 12 4.75 6 9.52 12Zm-16.36.21V1.35c2.53 0 5.07-.12 7.6-.19v23.78c-2.53.04-5.07.07-7.6.09Z"
        fill="#fff"
      />
      <path
        d="M151.3 1.17q-3.87 5.47-7.87 10.83l-.46.63.48.61q4.31 5.41 8.61 10.9l-7.36.14q-3.78-5.19-7.54-10.34l-.73-1.1.82-1.19q3.48-5.07 7-10.22c2.38-.08 4.75-.16 7.13-.26m-16.2.54v22.71l-6.46.07V1.87l6.46-.16M153.57 0c-3.31.14-6.63.27-9.94.38q-3.66 5.4-7.31 10.71V.64q-4.37.13-8.75.22v24.71l8.75-.1V14.55q3.94 5.39 7.89 10.81 5.12-.07 10.23-.18-5-6.34-10-12.6 4.54-6.22 9.08-12.58Z"
        fill="#c51f30"
      />
      <path
        d="M.58 58.47V32.9q9.62.2 19.25.35v5.55l-11.66-.11v4.2h10.58v4.86H8.17v4.89l12-.13v5.55q-9.74.21-19.59.41Z"
        fill="#fff"
      />
      <path
        d="M19.26 33.8v4.44l-10.52-.11H7.59v5.34h10.58v3.74H7.59v6h1.15l10.88-.12v4.44q-9.24.15-18.47.34V33.48q9 .18 18.11.32m1.17-1.1q-10.2-.16-20.4-.39v26.75q10.4-.24 20.73-.42v-6.66l-12 .12v-3.71h10.58v-6l-10.6-.06v-3.07l11.66.1V32.7Z"
        fill="#c51f30"
      />
      <path
        d="M35.07 58.21c-7.37.11-11.22-2.68-11.45-8.35h6.82c.39 2.39 1.8 3.45 4.52 3.42s2.85-1.49 2.85-1.94c0-1.58-2-2.08-4.58-2.71-4-1-9-2.22-9-7.8 0-4.69 3.8-7.77 9.47-7.68 6.59.1 10.13 2.74 10.79 8.09h-6.67a3.42 3.42 0 0 0-3.68-3.11c-2.64 0-3 1.35-3 2.18 0 1.59 2.05 2.07 4.64 2.67 4.13 1 9.28 2.13 9.28 7.77-.01 4.35-4.02 7.37-9.99 7.46Z"
        fill="#fff"
      />
      <path
        d="M33.73 33.64c6.07.08 9.32 2.31 10.13 7h-5.51a4 4 0 0 0-4.19-3.12c-3.34 0-3.6 2.08-3.6 2.73 0 2 2.35 2.57 5.07 3.2 5.01 1.19 8.8 2.4 8.8 7.19 0 4.1-3.78 6.9-9.4 7-6.84.1-10.39-2.26-10.84-7.25h5.74c.55 2.33 2.19 3.45 5 3.42 2.52 0 3.42-1.31 3.42-2.5 0-2-2.32-2.56-5-3.23-4.84-1.19-8.55-2.5-8.55-7.27 0-4.42 3.5-7.21 8.9-7.13m0-1.1c-5.85-.09-10 3.08-10 8.23 0 9.51 13.55 7.35 13.55 10.51 0 .79-.69 1.38-2.28 1.39-2.46 0-3.76-.86-4-3.42h-7.91c0 6.76 4.55 9.57 12 9.44 6.19-.09 10.56-3.23 10.56-8.09 0-9.52-13.91-7.25-13.91-10.43 0-1.1.83-1.65 2.45-1.64a2.92 2.92 0 0 1 3.15 3.12h7.84c-.39-4.81-3-9.06-11.42-9.19Z"
        fill="#c51f30"
      />
      <path
        d="M49.27 57.69V33.64l11.82.11c6 0 9.52 3.16 9.52 8.33 0 5.75-3.8 8.44-11.94 8.47h-1.8v7Zm7.6-12.2h2.38c2.37 0 3.68-1.15 3.68-3.22a3.26 3.26 0 0 0-3.57-3.21h-2.49Z"
        fill="#fff"
      />
      <path
        d="M61.09 34.28c5.6 0 9 3 8.94 7.8 0 5.46-3.5 7.91-11.36 7.94h-2.38v7.06l-6.45.06v-23l11.25.09m-4.8 11.74h3c2.7 0 4.26-1.37 4.26-3.75a3.84 3.84 0 0 0-4.15-3.74h-3.11v7.51m4.8-12.81-12.4-.11v25.16l8.74-.09v-7h1.23c9.15-.05 12.51-3.46 12.51-9s-3.74-8.87-10.08-8.93Zm-3.66 11.77v-5.31h1.92a2.71 2.71 0 0 1 3 2.67c0 1.51-.76 2.68-3.11 2.68Z"
        fill="#c51f30"
      />
      <path
        d="m90.14 57.42-4-5.22c-1-1.37-1.67-2.18-3.08-2.18h-1.17v7.43h-7.6V33.83l12 .05c6 0 9.52 2.94 9.52 7.82 0 4.31-2.28 5.86-4.27 6.74l-1.11.49 1.13.46a9.67 9.67 0 0 1 2.45 2.38l4.42 5.64Zm-8.25-12.27h2.54c2.45 0 3.69-1 3.69-3 0-1.48-1.11-3-3.58-3h-2.65Z"
        fill="#fff"
      />
      <path
        d="M86.3 34.41c5.6 0 8.94 2.74 8.94 7.29 0 4-2 5.41-3.94 6.26l-2.24 1 2.26.93a9.67 9.67 0 0 1 2.22 2.21c1.25 1.6 2.51 3.2 3.76 4.81h-6.87q-1.94-2.49-3.85-5c-1-1.37-1.81-2.37-3.52-2.37h-1.74v7.43h-6.46v-22.6H86.3m-4.98 11.27h3.11c3.71 0 4.26-2.22 4.26-3.55.02-1.7-1.26-3.45-4.13-3.45h-3.24v7m5-12.33-12.59-.05v24.75h8.75v-7.46h.58c1.12 0 1.59.63 2.61 2l4.19 5.44h9.7l-5.13-6.52a10 10 0 0 0-2.67-2.56c2.35-1 4.59-2.82 4.59-7.2 0-5.15-3.8-8.34-10.09-8.36Zm-3.89 11.33v-5h2.1c2 0 3 1.1 3 2.46s-.73 2.54-3.1 2.54Z"
        fill="#c51f30"
      />
      <path
        d="M101.37 57.41V33.9h19.29v5.27h-11.68v3.91h10.6v4.56h-10.6v4.51h12v5.28c-6.55 0-13.05-.02-19.61-.02Z"
        fill="#fff"
      />
      <path
        d="M120.09 34.39v4.25h-11.66v5h10.59v3.55h-10.59v5.56H120.47v4.22h-18.5V34.43h18.14m1.15-1.07q-10.22.06-20.44 0v24.58q10.39 0 20.8.05v-6.35h-12v-3.46h10.6v-5.65h-10.6v-2.89h11.69v-6.32Z"
        fill="#c51f30"
      />
      <path
        d="M135.92 57.89c-7.37-.06-11.23-2.82-11.46-8.23h6.83c.39 2.32 1.8 3.36 4.52 3.37s2.85-1.42 2.85-1.86c0-1.55-2-2-4.58-2.68-4-1-9-2.19-9-7.52 0-4.47 3.81-7.49 9.48-7.54 6.59 0 10.13 2.49 10.79 7.8h-6.67a3.36 3.36 0 0 0-3.69-3c-2.64 0-3 1.35-3 2.15 0 1.54 2 2 4.64 2.57 4.14.91 9.28 2.06 9.28 7.66-.01 4.42-4.02 7.33-9.99 7.28Z"
        fill="#fff"
      />
      <path
        d="M134.58 33.97c6.08 0 9.33 2.09 10.13 6.73h-5.51a4 4 0 0 0-4.19-3c-3.34 0-3.6 2.06-3.6 2.69 0 2 2.35 2.48 5.07 3.09 5 1.11 8.85 2.35 8.85 7.14 0 4.08-3.78 6.77-9.41 6.72-6.84-.05-10.41-2.39-10.85-7.16h5.75c.55 2.26 2.19 3.36 5 3.38 2.53 0 3.43-1.23 3.42-2.4 0-2-2.32-2.54-5-3.19-4.85-1.19-8.56-2.45-8.56-7 0-4.22 3.5-7 8.91-7m0-1.06c-5.86 0-10.05 3.17-10.05 8.07 0 9.08 13.56 7.1 13.56 10.2 0 .77-.69 1.34-2.28 1.33-2.46 0-3.76-.89-4-3.37h-7.92c0 6.43 4.55 9.22 12 9.29 6.18.06 10.55-3 10.56-7.78 0-9.49-13.92-7.15-13.92-10.24 0-1.07.82-1.61 2.45-1.62a2.85 2.85 0 0 1 3.15 3h7.85c-.4-4.77-3-8.93-11.43-8.86Z"
        fill="#c51f30"
      />
      <path
        d="M160.61 58.15c-7.37-.1-11.22-2.93-11.45-8.42h6.82c.39 2.35 1.8 3.42 4.52 3.45s2.85-1.45 2.85-1.9c0-1.59-2-2.1-4.58-2.74-4-1-9-2.24-9-7.66 0-4.54 3.8-7.63 9.47-7.71 6.59-.09 10.12 2.49 10.79 7.94h-6.67a3.37 3.37 0 0 0-3.69-3.07c-2.63 0-3 1.39-3 2.2 0 1.58 2 2 4.64 2.62 4.13.93 9.28 2.1 9.28 7.85 0 4.57-4.01 7.53-9.98 7.44Z"
        fill="#fff"
      />
      <path
        d="M159.27 33.74c6.07-.1 9.32 2.1 10.16 6.9h-5.51a4 4 0 0 0-4.19-3.06c-3.34 0-3.6 2.11-3.6 2.75 0 2 2.35 2.52 5.07 3.14 5 1.13 8.84 2.4 8.84 7.31 0 4.18-3.78 6.93-9.4 6.85-6.84-.09-10.4-2.49-10.84-7.34h5.74c.55 2.29 2.19 3.42 5 3.45 2.52 0 3.42-1.24 3.42-2.44 0-2-2.32-2.6-5-3.27-4.84-1.21-8.55-2.49-8.55-7.13 0-4.29 3.49-7.1 8.9-7.17m0-1.08c-5.85.08-10.05 3.28-10.05 8.25 0 9.22 13.56 7.23 13.56 10.39 0 .8-.69 1.37-2.28 1.36-2.46 0-3.76-.92-4-3.44h-7.92c0 6.53 4.56 9.39 12 9.51 6.18.09 10.55-3 10.55-7.93 0-9.73-13.91-7.32-13.91-10.47 0-1.09.83-1.64 2.45-1.66a2.88 2.88 0 0 1 3.15 3.08h7.84c-.4-4.89-3-9.15-11.42-9Z"
        fill="#c51f30"
      />
      <path
        d="M186.43 58.57c-8.06-.17-12.68-4.86-12.68-12.9s4.59-12.72 12.68-12.89 12.67 4.53 12.67 12.91-4.67 13.06-12.67 12.88Zm0-20.07c-2.29 0-5 1.29-5 7.18s2.73 7.16 5 7.18 5-1.22 5-7.22-2.74-7.17-5-7.14Z"
        fill="#fff"
      />
      <path
        d="M186.43 33.35c7.69-.16 12.1 4.33 12.1 12.34s-4.44 12.48-12.1 12.32-12.14-4.64-12.14-12.37 4.41-12.14 12.14-12.29m0 20.07c2.09 0 5.59-.93 5.59-7.74s-3.5-7.77-5.59-7.75-5.59 1.07-5.59 7.75 3.5 7.72 5.59 7.74m0-21.2c-8.81.19-13.26 5.57-13.26 13.45s4.42 13.27 13.26 13.47 13.25-5.22 13.25-13.45-4.47-13.66-13.25-13.47Zm0 20.08c-3.18 0-4.44-2.65-4.44-6.62s1.26-6.59 4.44-6.62 4.41 2.58 4.41 6.58-1.26 6.66-4.44 6.62Z"
        fill="#c51f30"
      />
      <path
        d="M34.3 90.29v-19l-7 .21V66q10.77-.27 21.55-.47v5.38l-7 .17v18.8Z"
        fill="#fff"
      />
      <path
        d="M48.31 66.13v4.3l-5.83.14-1.15.07v18.77l-6.45.3V70.77h-1.15l-5.83.18v-4.31q10.2-.27 20.41-.46m1.12-1.15q-11.35.19-22.69.48v6.61l7-.22v19c2.92-.15 5.83-.29 8.75-.42V71.64l7-.17v-6.44Z"
        fill="#c51f30"
      />
      <path
        d="M63.26 89.49c-8.07.24-12.69-4.07-12.69-11.93s4.62-12.44 12.69-12.54 12.7 4.3 12.7 12-4.63 12.23-12.7 12.47Zm0-19c-2.29 0-5 1.27-5 6.93s2.73 6.76 5 6.7 5-1.3 5-6.91-2.71-6.81-5-6.77Z"
        fill="#fff"
      />
      <path
        d="M63.26 65.56c7.71-.1 12.17 4.08 12.17 11.49s-4.42 11.68-12.13 11.9-12.12-3.89-12.12-11.4 4.42-11.9 12.12-12m0 19c2.09 0 5.6-1.08 5.6-7.45s-3.51-7.28-5.6-7.24-5.59 1-5.59 7.47 3.46 7.31 5.55 7.31m0-20.11c-8.83.11-13.27 5.33-13.27 13.05s4.44 12.71 13.27 12.44 13.27-5.38 13.27-12.99-4.44-12.64-13.27-12.54Zm0 19.05c-3.18.08-4.45-2.38-4.45-6.19s1.27-6.33 4.45-6.38 4.45 2.42 4.45 6.2-1.28 6.25-4.45 6.33Z"
        fill="#c51f30"
      />
      <path
        d="M80.36 88.78V65.19q9.46-.06 18.93-.07v5.26H87.97v4.91H98.2v4.59H87.97v8.78c-2.54.05-5.07.08-7.61.12Z"
        fill="#fff"
      />
      <path
        d="M98.71 65.64v4.2H87.43v6h10.24v3.54H87.43v8.78l-6.45.07V65.71q8.84-.07 17.73-.07m1.15-1q-10 0-20.07.07v24.61c2.91-.05 5.83-.08 8.75-.11v-8.77h10.24v-5.69H88.54v-3.82h11.32v-6.29Z"
        fill="#c51f30"
      />
      <path
        d="M103.51 88.64V65.13q9.47 0 18.93.08v5.28c-3.78 0-7.55-.06-11.33-.08v4.91l10.24.08v4.61c-3.41 0-6.82-.08-10.24-.1v8.73Z"
        fill="#fff"
      />
      <path
        d="M121.86 65.74v4.22c-3.39 0-6.78-.06-10.17-.08h-1.15v6h1.15c3 0 6.06 0 9.09.08v3.55q-4.55-.06-9.09-.09h-1.15v8.77h-6.46V65.64q8.89 0 17.78.09m1.15-1q-10-.08-20.08-.09v24.53h8.76v-8.73c3.41 0 6.82.06 10.23.11v-5.67q-5.11-.06-10.23-.09v-3.85c3.77 0 7.55 0 11.32.09v-6.34Z"
        fill="#c51f30"
      />
      <path
        d="M126.65 88.91V65.25q9.65.09 19.29.25v5.35c-3.89-.08-7.79-.15-11.68-.21v4c3.53.06 7.06.14 10.59.22v4.67c-3.53-.09-7.06-.18-10.59-.25v4.55q6 .15 12 .33v5.36q-9.83-.41-19.61-.61Z"
        fill="#fff"
      />
      <path
        d="M145.36 66.03v4.27q-5.27-.1-10.53-.18h-1.14v5h1.14l9.45.2v3.6c-3.15-.08-6.3-.16-9.45-.22h-1.14v5.61h1.14c3.63.09 7.27.19 10.9.3v4.28q-9.26-.33-18.5-.52V65.79q9.08.09 18.13.24m1.15-1.06q-10.22-.17-20.43-.26v24.72q10.4.21 20.79.6v-6.39q-6-.18-12-.33v-3.54l10.6.26v-5.74q-5.3-.14-10.6-.22v-2.89c3.9.06 7.79.14 11.68.22v-6.43Z"
        fill="#c51f30"
      />
      <path
        d="M150.7 89.64v-24q9.63.16 19.27.41v5.49q-5.83-.2-11.67-.34v4q5.3.15 10.59.35v4.78q-5.29-.22-10.59-.4v4.61q6 .24 12 .53v5.48q-9.78-.52-19.6-.91Z"
        fill="#fff"
      />
      <path
        d="M169.43 66.53v4.38q-5.26-.17-10.53-.3h-1.14v5.11h1.14l9.44.31v3.69q-4.71-.2-9.44-.36h-1.14v5.73h1.14q5.44.21 10.89.48v4.38q-9.24-.49-18.49-.85v-23q9.07.17 18.13.4m1.14-1.07q-10.2-.25-20.41-.43v25.15q10.38.4 20.78 1v-6.61q-6-.29-12-.52v-3.56c3.53.13 7.06.26 10.59.41v-5.88c-3.53-.13-7.06-.24-10.59-.35V71.7c3.89.1 7.78.22 11.67.34v-6.58Z"
        fill="#c51f30"
      />
    </svg>
  )
}

export default BaconBrittleMobile
