import * as React from "react"

function BaconBrittleDesktop(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width={1176.68}
      // height={153.94}
      viewBox="0 0 1176.68 153.94"
      {...props}
    >
      <path
        d="M2 151.73V2.21Q14.72 3.55 27.46 4.8c18 2 30.58 31.81 30.58 72.55 0 40.3-12.58 69.74-30.58 71.79Q14.72 150.4 2 151.73Zm17.72-36.38 7.11-.38c8.33-.49 13.3-14.69 13.3-38s-5-37.51-13.3-38l-7.11-.38Z"
        fill="#fff"
      />
      <path
        d="M27.46 6.73c9.84 1 16.26 11.37 19.91 19.74C53 39.38 56 57.3 56 77.36c0 20.29-3 37.6-8.66 50.48-6.58 14.92-14.46 18.86-19.92 19.37Q15.71 148.35 4 149.53V4.41q11.73 1.18 23.47 2.32M17.72 117.4l4-.22 5.12-.28c9.72-.6 15.3-15.14 15.3-39.93s-5.58-39.33-15.3-39.93l-5.12-.28-4-.22v80.86M27.46 2.92Q13.72 1.49 0 0v153.92q13.73-1.49 27.46-2.88C46.93 148.8 60 119.25 60 77.35S46.93 5.14 27.46 2.92ZM21.71 113.3V40.64l5.12.25c6.91.39 11.31 14.62 11.31 36.08s-4.4 35.69-11.31 36.08l-5.12.25Z"
        fill="#c51f30"
      />
      <path
        d="M106.45 142.04q-.95-7.51-1.88-15.05-10.69.66-21.38 1.37-.9 7.88-1.8 15.78-9.75.85-19.52 1.74 10-69.83 20.11-136 12 1 23.89 2Q115.94 77.79 126 140.49q-9.77.75-19.55 1.55ZM86.64 98.38q7.2-.21 14.4-.39-3.63-29.86-7.26-60.27-3.57 30.06-7.14 60.66Z"
        fill="#fff"
      />
      <path
        d="M104.13 13.45q9.78 64.3 19.58 125.52-7.74.58-15.46 1.19l-1.5-12-.39-3.07-3.59.22L85 126.46l-3.6.23c-.12 1.08-.25 2.16-.37 3.24q-.72 6.28-1.43 12.57-7.71.66-15.44 1.34 9.8-67.72 19.58-132 10.2.86 20.4 1.66m-19.72 86.72 4.43-.13 10-.29 4.44-.13c-.15-1.28-.31-2.56-.46-3.84q-2.52-20.62-5-41.5-2-16.65-4-33.47-2 16.54-3.94 33.25-2.47 20.94-4.94 42.16c-.15 1.32-.31 2.63-.46 4m23.2-90Q93.92 9.12 80.24 7.92q-10.33 68.12-20.65 140 11.8-1.11 23.61-2.16.9-7.84 1.8-15.73 8.9-.62 17.78-1.19.95 7.56 1.89 15.08 11.81-1 23.62-1.92-10.34-64.22-20.67-131.74ZM88.85 96.54q2.48-21.11 4.94-41.95 2.52 21 5 41.7l-10 .25Z"
        fill="#c51f30"
      />
      <path
        d="M175.66 136.92q-5-12.82-10.06-25.94c-2.71-7.14-4.07-10.77-8.31-10.66l-3.44.09v38q-8.86.64-17.74 1.31V14.22q14.63 1.11 29.26 2.12c14.23 1.07 23.07 16.79 23.07 41.12 0 22.41-6.22 30.48-10.89 34.7l-1.25 1.13 1.29 1c1.2 1 4 6.36 6.18 11.94q5.88 14.85 11.77 29.32-9.94.68-19.88 1.37Zm-21.81-63.6h6.94c9.73 0 9.73-10.61 9.73-14.11 0-8.61-3.63-14.1-9.46-14.33l-7.21-.27Z"
        fill="#fff"
      />
      <path
        d="M165.37 17.92c6.09.41 11.07 3.92 14.79 10.31 4.11 7.07 6.28 17.1 6.28 29.15 0 21.91-5.92 29.68-10.37 33.69l-2.54 2.24 2.59 2.1c.95.88 3.54 5.78 5.74 11.36q5.49 13.86 11 27.39l-15.69 1q-4.78-12.17-9.56-24.59c0-.07-.05-.13-.07-.19-2.8-7.38-4.5-11.89-10.22-11.75h-1.44l-4 .11v38.18q-6.87.48-13.74 1V16.04q13.63 1 27.26 1.92m-13.55 56.96h8.94c7.78 0 11.73-5.28 11.73-15.7 0-11.45-5.92-15.77-11.46-16l-5.21-.2-4-.16v32.06m13.52-60.2q-15.63-1.11-31.26-2.33v129.16q10.88-.85 21.74-1.64v-37.93h1.44c2.78-.08 4 3.12 6.47 9.76q5.2 13.62 10.42 26.91 12-.84 24.08-1.61-6.3-15.42-12.58-31.24c-1.8-4.54-4.85-11.08-6.65-12.54 5.84-5.26 11.41-14.14 11.41-35.71 0-25.26-9.44-41.57-25.07-42.78Zm-9.52 57V46.35l5.21.19c4.94.18 7.46 5.79 7.46 12.66 0 7.54-1.89 12.55-7.73 12.53h-4.94Z"
        fill="#c51f30"
      />
      <path
        d="M244.24 132.72q-11-27.71-22.06-56.64 10.32-28 20.64-54.94 10.26.55 20.51 1.09-11 26.21-22 53.51 12 28.59 24.08 55.87-10.56.54-21.17 1.11Zm-40.84 2.38V18.84l17.75 1.08v114.11Z"
        fill="#fff"
      />
      <path
        d="M260.65 23.55q-10.51 25.15-21 51.32l-.36.88c.13.29.25.58.37.87q11.55 27.43 23.09 53.66l-17 .88q-9.38-23.54-18.75-47.95-1.37-3.56-2.73-7.14 1.37-3.72 2.74-7.41 8.67-23.38 17.34-45.95 8.15.43 16.31.84m-41.5-2.23v111.3l-13.75.81V20.51l13.75.81m46.84-.4q-12.36-.64-24.72-1.35-9.08 23.7-18.15 48.25V18.51q-10.87-.66-21.75-1.35v119.62q10.88-.69 21.75-1.35V84.08q9.79 25.61 19.59 50.22 12.72-.72 25.44-1.38-12.36-27.94-24.72-57.19 11.3-28 22.56-54.81Z"
        fill="#c51f30"
      />
      <path
        d="M298 130.04V23.92q23.4 1.05 46.8 1.93v24.21q-14.52-.28-29-.61v15.28l26.34.24v21.44l-26.34.19v17.89q15-.34 29.94-.63v24.22q-23.85.84-47.74 1.96Z"
        fill="#fff"
      />
      <path
        d="M342.84 27.12v21.53q-12.52-.25-25-.55l-4-.09V66.1h4l22.34.18v18.77l-22.34.14h-4v20.73l4-.1 25.94-.56v21.52q-21.86.8-43.7 1.75V25.4q21.4.93 42.8 1.72m4-2.58q-25.39-1-50.8-2.15v109.16q25.84-1.2 51.7-2.18v-26.91q-15 .27-29.94.59V87.98l26.34-.22V63.62q-13.17-.12-26.34-.27V50.92q14.52.3 29 .57V24.54Z"
        fill="#c51f30"
      />
      <path
        d="M383.35 128.18c-18.21.64-27.6-11.16-27.94-35.21l15.78-.17c.6 7.09 2.7 13.89 11.89 13.71 4.8-.09 7.66-2.7 7.67-7 0-5.4-5.49-7.54-11.3-9.84C370 85.98 357 80.75 357 57.27c0-19.43 9.24-32.11 23-31.62 16 .56 24.88 12.1 26.34 34.33l-15.44-.16c-.48-11.14-7-12.51-9.8-12.56-6.71-.14-8.12 4.2-8.12 7.86 0 5.46 5.58 7.58 11.47 9.8 9.77 3.7 23.16 8.63 23.16 32.09 0 18.17-9.75 30.67-24.26 31.17Z"
        fill="#fff"
      />
      <path
        d="M380 26.99c8 .25 13.92 3.5 18 9.86 3.38 5.25 5.47 12.56 6.22 21.79l-11.45-.12c-1-11.11-8.4-12.53-11.71-12.6-3-.06-10.12.71-10.12 9.17 0 6.25 6.36 8.68 12.5 11 9.86 3.72 22.13 8.24 22.13 31 0 17.14-9.16 29.38-22.26 29.82-16.56.56-25.26-10.34-25.91-32.55l11.86-.14c.57 5.55 2.72 13.9 13.78 13.68 7.13-.14 9.67-4.39 9.67-8.32 0-6.14-6.24-8.58-12.26-11-9.53-3.8-21.45-8.6-21.45-31.28 0-18.3 8.64-30.75 21-30.31m0-2.67c-14.57-.54-25 12.16-25 32.93 0 38.4 33.72 29.61 33.72 42.32 0 3.17-1.71 5.54-5.66 5.61-6.12.11-9.36-3.44-10-13.74l-19.69.2c0 27.29 11.33 38.53 30 37.87 15.38-.55 26.26-13.24 26.26-32.53C409.61 58.92 375 67.92 375 55.15c0-4.45 2.07-6.64 6.12-6.56 3.15.06 7.82 2.48 7.82 12.54l19.52.17c-1-19.19-7.47-36.23-28.42-37Z"
        fill="#c51f30"
      />
      <path
        d="M419.25 125.78V28.16q14.43.36 28.85.67c14.25.32 23.1 13.32 23.1 34 0 23.31-9.26 34.23-29.12 34.44h-5.06v28.06ZM437 75.44h6.5c6.46 0 9.73-4 9.73-11.94 0-8.71-5.1-11.86-9.46-11.92l-6.77-.07Z"
        fill="#fff"
      />
      <path
        d="M448.1 30.11c6.08.13 11 2.88 14.78 8.14 4.13 5.83 6.32 14.31 6.32 24.55 0 11.05-2.22 19.4-6.59 24.86s-11 8.21-20.53 8.3H435.02v28.09l-13.77.32V29.51q13.42.33 26.85.6M435 76.73h8.5c9.7 0 11.73-7.18 11.73-13.21 0-7.94-4.5-13.13-11.46-13.21l-4.77-.06h-4v26.48m13.1-49.19q-15.42-.33-30.85-.74v100.34q10.89-.3 21.77-.54v-28h3.06c22.75-.25 31.12-13.78 31.12-35.71 0-21.53-9.45-34.91-25.1-35.28ZM439 74.15V52.83l4.77.05c4.94 0 7.46 5.15 7.46 10.62 0 6-1.89 10.66-7.73 10.66Z"
        fill="#c51f30"
      />
      <path
        d="M521.09 123.98 511 103.38c-2.71-5.61-4.07-8.43-8.32-8.41h-3.44v29.25q-8.88.1-17.77.25v-95q14.65.22 29.31.4c14.24.17 23.09 12.27 23.09 31.64 0 17.8-6.22 24.17-10.9 27.42l-1.26.87 1.3.84c1.2.78 4 5 6.18 9.51q5.89 11.87 11.8 23.68-9.94.09-19.9.15Zm-21.83-49.81h6.95c9.74 0 9.74-8.31 9.74-11 0-6.74-3.63-10.94-9.47-11h-7.22Z"
        fill="#fff"
      />
      <path
        d="M510.8 31.1a18 18 0 0 1 14.8 7.51c4.12 5.42 6.29 13.32 6.29 22.87 0 17.38-5.93 23.49-10.39 26.59l-2.5 1.72 2.59 1.68c1 .71 3.54 4.59 5.74 9l11 22.07-15.71.13q-4.83-9.75-9.62-19.51l-.08-.15c-2.8-5.81-4.49-9.33-10.23-9.3H497.25v29.26l-13.77.2V30.74q13.67.21 27.31.36m-13.53 44.33h8.95c4.37 0 7.83-1.49 9.72-4.19 1.36-1.93 2-4.58 2-8.11 0-7.5-4.4-12.19-11.47-12.24H497.24v24.6m13.56-46.9q-15.66-.18-31.31-.45v97.66q10.89-.19 21.77-.33V96.24h1.44c2.79 0 4 2.47 6.48 7.69q5.22 10.68 10.43 21.33l24.11-.21q-6.3-12.62-12.59-25.29c-1.8-3.63-4.86-8.84-6.66-10 5.85-4.06 11.43-11.09 11.43-28.26 0-20.15-9.45-32.7-25.1-32.9Zm-9.54 44.33V53.38h5.22c4.95 0 7.47 4.36 7.47 9.71 0 5.88-1.9 9.8-7.74 9.79Z"
        fill="#c51f30"
      />
      <path
        d="M548.87 123.76V30.18q23.42.14 46.83.11v22.12h-29.06v13.62H593v19.54h-26.36v16h30v22.12q-23.9-.07-47.77.07Z"
        fill="#fff"
      />
      <path
        d="M593.69 31.54v19.63h-29.05v16.11H591v17h-26.37v18.47h30v19.62q-21.86 0-43.72.1V31.44q21.4.12 42.82.1m4-2.5q-25.43 0-50.83-.12v96.1q25.86-.16 51.73-.12v-24.61h-30V86.82H595v-22h-26.36V53.64h29.06v-24.6Z"
        fill="#c51f30"
      />
      <path
        d="M634.22 125.1c-18.21-.13-27.61-11.33-28-33.41H622c.6 6.61 2.71 13 11.9 13 4.8 0 7.67-2.38 7.67-6.42 0-5.11-5.49-7.2-11.3-9.41-9.47-3.6-22.44-8.49-22.44-30.07 0-17.86 9.25-29.87 23-30 16-.12 24.88 10.56 26.35 31.85h-15.47c-.47-10.54-6.95-11.68-9.8-11.67-6.71 0-8.12 4.09-8.12 7.51 0 5.08 5.57 7 11.47 9 9.78 3.37 23.17 8 23.17 30.52.06 17.61-9.7 29.22-24.21 29.1Z"
        fill="#fff"
      />
      <path
        d="M630.89 30.11c8 0 13.92 2.79 18 8.72 3.38 4.88 5.46 11.82 6.22 20.63h-11.46c-1-10.51-8.4-11.67-11.71-11.66-3.05 0-10.12.89-10.12 8.76 0 5.81 6.35 8 12.5 10.13 9.86 3.41 22.13 7.66 22.13 29.42 0 16.44-9.15 27.8-22.26 27.7-16.53-.07-25.19-10.44-25.89-30.89h11.86c.58 5.16 2.73 13 13.79 13 7.13 0 9.67-3.94 9.67-7.67 0-5.81-6.23-8.19-12.26-10.47-9.57-3.64-21.48-8.13-21.48-29 0-16.84 8.64-28.63 21-28.71m0-2.5c-14.58.1-25 12.17-25 31.22 0 35.23 33.74 27.5 33.74 39.47 0 3-1.72 5.19-5.67 5.18-6.12 0-9.36-3.41-10-13h-19.7c0 25 11.33 35.76 30 35.9 15.38.12 26.27-11.62 26.27-30.18 0-36.59-34.64-27.62-34.64-39.58 0-4.15 2.07-6.24 6.12-6.25 3.15 0 7.83 2.18 7.82 11.67h19.53c-1-18.42-7.47-34.51-28.43-34.35Z"
        fill="#c51f30"
      />
      <path
        d="M695.65 125.82c-18.2-.32-27.6-11.71-27.94-34l15.78.07c.6 6.69 2.71 13.15 11.9 13.24 4.8 0 7.66-2.37 7.67-6.48 0-5.2-5.49-7.34-11.3-9.59-9.47-3.67-22.44-8.64-22.44-30.42 0-18 9.25-30.24 23-30.48 16-.27 24.88 10.51 26.34 32.22l-15.46.1c-.48-10.71-7-11.83-9.8-11.81-6.71.07-8.12 4.2-8.12 7.65 0 5.15 5.57 7.08 11.47 9.14 9.78 3.39 23.16 8.1 23.16 31.06.01 17.83-9.74 29.57-24.26 29.3Z"
        fill="#fff"
      />
      <path
        d="M692.33 29.44c8-.13 13.92 2.7 18 8.71 3.38 5 5.47 12 6.22 21l-11.45.08c-1-10.69-8.4-11.82-11.71-11.79-3 0-10.12 1-10.12 8.92 0 5.89 6.35 8.09 12.5 10.24 9.86 3.43 22.13 7.75 22.13 29.94 0 16.76-9.15 28.26-22.26 28-16.56-.28-25.27-10.83-25.92-31.45h11.86c.58 5.22 2.73 13.14 13.79 13.26 7.13.07 9.67-3.95 9.67-7.74 0-5.91-6.24-8.35-12.26-10.68-9.57-3.71-21.48-8.27-21.48-29.35 0-17 8.64-29 21-29.2m0-2.53c-14.58.25-25 12.54-25 31.75 0 35.54 33.73 27.82 33.73 40 0 3-1.71 5.27-5.66 5.23-6.12 0-9.36-3.5-10-13.23l-19.7-.07c0 25.21 11.34 36.19 30 36.52 15.38.29 26.26-11.59 26.26-30.54 0-37.34-34.63-28.11-34.63-40.23 0-4.21 2.07-6.33 6.12-6.37 3.15 0 7.82 2.17 7.82 11.82l19.52-.11c-1-18.8-7.46-35.14-28.42-34.75Z"
        fill="#c51f30"
      />
      <path
        d="M759.88 127.2c-19.42-.57-31-19.3-31-50.23s11.59-49.66 31-50.23 31 18.12 31 50.23-11.59 50.83-31 50.23Zm0-76.89c-8.79.13-13.07 8.85-13.07 26.66s4.28 26.53 13.07 26.66 13.06-8.57 13.06-26.66-4.27-26.8-13.06-26.66Z"
        fill="#fff"
      />
      <path
        d="M759.88 28.04c8.85-.23 15.92 3.82 21 12.15 5.23 8.56 8 21.32 8 36.78s-2.76 28.22-8 36.78c-5.09 8.34-12.16 12.38-21 12.15s-15.93-4.62-21-13c-5.23-8.58-8-21-8-36s2.76-27.39 8-36c5.09-8.34 12.16-12.72 21-13m0 76.9c7.28.11 10.74-4.69 12.37-8.75 1.79-4.47 2.69-10.94 2.69-19.22 0-12.11-1.56-28.2-15.06-28-7.28.11-10.75 5-12.38 9-1.78 4.45-2.69 10.82-2.69 19 0 11.91 1.56 27.75 15.07 28m0-79.5c-22 .66-33 21.28-33 51.53s11.06 50.87 33 51.53 33-20.06 33-51.53-11.07-52.23-33-51.53Zm0 76.89c-7.92-.12-11.07-10.13-11.07-25.36s3.19-25.13 11.07-25.25 11.06 9.93 11.06 25.36-3.15 25.48-11.06 25.36Z"
        fill="#c51f30"
      />
      <path
        d="M837.87 128.34V49.92l-17.36.34v-24q26.24-.93 52.47-2.09v25l-17.36.39v79.48q-8.88-.36-17.75-.7Z"
        fill="#fff"
      />
      <path
        d="M871 25.64v22.18l-13.36.31-4 .1v79.35l-13.75-.53V48.53l-4 .08-13.38.31v-21.4q24.24-.84 48.47-1.88m4-3q-28.23 1.28-56.47 2.28v26.75l17.35-.32v78.28q10.89.43 21.76.89v-79.6l17.38-.38V22.65Z"
        fill="#c51f30"
      />
      <path
        d="M909.31 133.02c-19.4-1.13-31-22-31-56s11.59-54.92 31-56 31 19.73 31 56.05-11.59 57.11-31 55.95Zm0-85.8c-8.78.26-13 10-13 29.75s4.27 29.49 13 29.75 13.06-9.45 13.06-29.75-4.27-30.05-13.06-29.75Z"
        fill="#fff"
      />
      <path
        d="M909.31 22.37c8.86-.45 15.92 3.92 21 13.22 5.22 9.54 8 23.93 8 41.38s-2.76 31.84-8 41.38c-5.09 9.3-12.15 13.67-21 13.22s-15.91-5.49-21-14.8c-5.22-9.58-8-23.27-8-39.8s2.77-30.22 8-39.8c5.09-9.31 12.15-14.34 21-14.8m0 85.81c7.28.22 10.74-5.12 12.37-9.66 1.79-5 2.69-12.25 2.69-21.55 0-13.6-1.56-31.65-15.06-31.21-7.31.22-10.74 5.65-12.31 10.16-1.79 4.95-2.69 12-2.69 21.06 0 13.2 1.56 30.78 15 31.21m0-88.72c-21.93 1.31-33 24.26-33 57.51s11.06 56.2 33 57.51 33-21.89 33-57.51-11-58.85-33-57.51Zm0 85.81c-7.91-.23-11-11.39-11-28.3s3.15-28.07 11-28.3 11.06 11 11.06 28.3-3.14 28.53-11.06 28.3Z"
        fill="#c51f30"
      />
      <path
        d="M952.39 133.85V20.09q22.93-1.34 45.87-2.86v28.31l-28.13 1v21.69l25.43-.25v24.94l-25.43-.43v42.43q-8.86-.55-17.74-1.07Z"
        fill="#fff"
      />
      <path
        d="M996.26 18.92v25.1q-12.06.43-24.13.85l-4 .14V69.79h4l21.43-.17v21.66l-21.43-.36-4-.06V133.21l-13.74-.8V21.5q20.94-1.2 41.87-2.55m4-3.46q-24.93 1.72-49.87 3.2v116.56q10.88.64 21.74 1.33V94.05q12.72.23 25.43.48V66.37l-25.43.29V47.97q14.07-.44 28.13-.91V15.49Z"
        fill="#c51f30"
      />
      <path
        d="M1009.89 137.5V16.44q22.94-1.59 45.86-3.38v30.28q-14.05.59-28.12 1.12v23.19l25.42-.29v26.65q-12.71-.27-25.42-.51v45.26q-8.86-.64-17.74-1.26Z"
        fill="#fff"
      />
      <path
        d="M1053.75 14.92v26.8q-12.06.53-24.12 1l-4 .16v26.43h4l21.42-.2v23.16l-21.42-.35-4-.07v45.16l-13.74-1V17.92q20.94-1.42 41.86-3m4-3.73q-24.91 2-49.85 3.77v124q10.88.76 21.74 1.58V95.19l25.42.56V65.63l-25.42.34V46.03q14.05-.51 28.11-1.06V11.19Z"
        fill="#c51f30"
      />
      <path
        d="M1067.37 141.81V12.13q23.37-1.87 46.74-4v32.62q-14.5.69-29 1.34v19.36l26.31-.54v28.7l-26.31-.43v22.67l29.9 1.38v32.69q-23.82-2.19-47.64-4.11Z"
        fill="#fff"
      />
      <path
        d="M1112.11 10.14v28.87q-12.49.63-25 1.21l-4 .19v22.84l4-.07 22.31-.4v25l-22.31-.31h-4v26.14l4 .19q12.95.6 25.9 1.25v28.87q-21.83-1.92-43.64-3.65V13.71q21.38-1.7 42.74-3.57m4-4q-25.36 2.37-50.73 4.46v132.8q25.81 2.13 51.62 4.55v-36.46q-14.94-.69-29.89-1.32V90.99l26.3.48V59.03q-13.14.31-26.3.6V43.77q14.49-.62 29-1.27V6.11Z"
        fill="#c51f30"
      />
      <path
        d="M1127.08 147.01V6.92q23.36-2.19 46.71-4.63v35.39q-14.49.81-29 1.55v21l26.3-.63v31.07l-26.3-.49v24.53q14.94.76 29.88 1.59v35.43q-23.78-2.49-47.59-4.72Z"
        fill="#fff"
      />
      <path
        d="M1171.79 4.5v31.31q-12.49.72-25 1.4l-4 .21v24.71l4-.08 22.3-.47v27.07l-22.3-.35-4-.06v28.28l4 .21q12.95.7 25.89 1.45v31.35q-21.81-2.21-43.62-4.21V8.62q21.38-2 42.72-4.12m4-4.4q-25.35 2.73-50.7 5.16v143.42q25.8 2.48 51.6 5.26v-39.53q-14.94-.78-29.88-1.51V92.14l26.29.56V57.52l-26.29.69V41.04q14.49-.7 29-1.47V.1Z"
        fill="#c51f30"
      />
    </svg>
  )
}

export default BaconBrittleDesktop
